import {T, useSetLanguage} from '@tolgee/react';
import {IcoCross, IcoDone} from 'myeuroloppet-shared';
import {useUserContext} from 'hooks/useUserContext';
import React, {ReactElement, useEffect} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {getBrowserLocale} from 'services/Utils';

export default function OrderDonePage(): ReactElement {
	console.log('<<OrderDonePage>>')
	const {user} = useUserContext();
	const loc = useLocation();
	const lang = getBrowserLocale(true) || 'en';
	const setLang = useSetLanguage();
	const params = new URLSearchParams(loc.search);
	// const orderId = params.get('oid)');
	const result = params.get('r');
	const reason = params.get('fr');
	const isSuccess = result === 's';

	useEffect(() => {
		setLang(lang);
	}, []);

  return (
	  <div className="Page Page--simple OrderDonePage">
		  <div className="MyLogo">My.Euroloppet</div>
		  <div className="Page__Container">
			  <div className="box">
				  <h2><T>Order completed</T></h2>
				  <div className={`Result ${isSuccess ? 'Result--Success' : 'Result--Fail'}`}>
					  {isSuccess && <div className='Result__Status'><IcoDone /> <T>The payment transaction was successfully completed.</T></div>}
					  {!isSuccess &&
						  <div className='Result__Status'>
							  <IcoCross />
							  <span><T>The payment has failed.</T></span>
							  {reason && <div className='Result__Status__Reason'>{reason}</div>}
							</div>
						}

					  {isSuccess &&
						  <div className='Result__Status'>
							 <p className=''><T>You always find details of your passport here in your Euroloppet account.</T></p>
							 <p><T>We have sent you an email with the Passport Details as well.</T></p>
						  </div>
						}

					  {user &&
						  <p className='mt30'><Link to='/dashboard' className='Button'><T>Return to the dashboard</T></Link></p>
					  }
				  </div>
			  </div>
		  </div>
	  </div>
  );
}
