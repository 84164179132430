import Avatar from 'components/User/Avatar';
import {IcoCross, IPassport} from 'myeuroloppet-shared';
import IUser from 'definitions/IUser';
import React from 'react';
import {T} from '@tolgee/react';
import countryList from 'react-select-country-list';
import CountryFlagImage from "../User/CountryFlagImage";

interface Props{
	user: IUser,
	passport: IPassport,
	onClose: () => void,
}

export default function PassportDetail(props: Props) {
	const {user, passport, onClose} = props;

	return (
		<div className='PassportDetail'>
			<div className='Container'>
				<button className="CloseButton" onClick={onClose}><IcoCross /></button>
				<div className='PassportDetail__Image'>
					<img src='passport.png' />
				</div>
				<div className='PassportDetail__Properties' >
					<div className='PassportDetail__Properties__Passport'>
						<div className='PassportDetail__Properties__Passport__Label'><T>Passport</T>:</div>
						<div className='PassportDetail__Properties__Passport__Number'>{passport.id}</div>
					</div>
					<div className='PassportDetail__Properties__Valid'><T>Valid to</T>: <strong>{(new Date(passport.validTo)).toLocaleDateString()}</strong></div>

					<div className='PassportDetail__Properties__Owner'>
						<div className='PassportDetail__Properties__Owner__Label'><T>Owner</T>:</div>
						<div className='PassportDetail__Properties__Owner__Values'>
							<div className='PassportDetail__Properties__Owner__Values__Firstname'>{user.degree} {user.firstname}</div>
							<div className='PassportDetail__Properties__Owner__Values__Lastname'>{user.lastname} </div>
							<div>{(new Date(user.dob ?? '')).toLocaleDateString()}</div>
							{user.nationality &&
								<div className='PassportDetail__Properties__Owner__Values__Nationality'>
									<CountryFlagImage country={user.nationality ? user.nationality.toLowerCase() : undefined} alt='Nationality flag' title={user.nationality} />
									<span>{countryList().getLabel(user.nationality)}</span>
								</div>
							}
						</div>
						<div className='PassportDetail__Properties__Owner__Avatar'>
							{user.avatar && <Avatar withUpload={false} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
