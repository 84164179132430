import {IcoDone} from 'myeuroloppet-shared';
import {ReactElement} from 'react';

interface Props {
	count: number,
	doneRaces: string[],
	doneSeries?: number[],
	completed: boolean
}

export default function TrophyProgress(props: Props): ReactElement {
	const countArray = Array(props.count).fill(true);
	const doneCount = Math.max(props.doneRaces.length, (props.doneSeries?.length ?? 0));
	//console.log('<<TrophyProgress>>')
	//console.log(countArray)
	//console.log(props.doneRaces)
	
	return (
		<div className='Trophy__Progress'>
			{countArray.map((n, i) => {
				const done = i < doneCount;
				return <div className={`Trophy__Progress__Item ${done ? 'Trophy__Progress__Item--done' : ''}`} key={i}>
					<IcoDone />
				</div>
			})}
		</div>
	)
}
