"use strict";
exports.__esModule = true;
exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "new";
    OrderStatus["PAID"] = "paid";
    OrderStatus["CANCELED"] = "canceled";
})(OrderStatus || (OrderStatus = {}));
exports.OrderStatus = OrderStatus;
