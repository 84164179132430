import {T} from '@tolgee/react';
import ContactForm from 'components/ContactForm';
import React, {ReactElement} from 'react';

export default function ContactPage(): ReactElement {

  return (
	  <div className="ContactPage">
		  <div className="box ContactPage__Address">
			  <h1><T>Contact</T></h1>

			  <h2>EUC / Euroloppet</h2>
			  <p className='mb15'><T>European Union of Cross-country Skiing Marathons</T></p>

			  <div className='row'>
				  <div>
					  <div className='label'><T>Headquarters:</T></div>
					  <div>Euroloppet Hauptgeschäftsstelle</div>
					  <div>Ramsau 350</div>
					  <div>8972 Ramsau am Dachstein</div>
					  <div>Österreich</div>
				  </div>
			  </div>
		  </div>

		  <div className="box ContactPage__Form">
			  <div className='row'>
				  <div>
			      <h3><T>Seeking for more info?</T></h3>
					  <ul>
					    <li><a href='https://www.euroloppet.com/euroloppet-pass/vorteile-euroloppet-pass/' target='_blank'><T>Check all advantages of Euroloppet Passport</T></a></li>
					    <li><a href='https://www.euroloppet.com/rennen/euroloppet-rennkalender/' target='_blank'><T>Euroloppet race calendar</T></a></li>
					  </ul>
				  </div>
				  <div>
			      <h3><T>Contact the office</T></h3>
			      <ContactForm />
				  </div>
			  </div>
		  </div>
	  </div>
  );
}
