import {IUserContextProps} from 'definitions/IUserContextProps';
import {useContext} from 'react';
import {UserContext} from 'services/UserContextProvider';

export const useUserContext = (): IUserContextProps => {
	const context = useContext(UserContext);
	if (!context) {
		throw Error('Context used out of it\'s provider.');
	}
	return context;
}
