"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function ProgressDots(props) {
    var _a, _b, _c;
    var count = (_a = props.count) !== null && _a !== void 0 ? _a : 5;
    var speed = (_b = props.speed) !== null && _b !== void 0 ? _b : 1.5;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("style", { children: "\n\t\t\t\t\t.ProgressDots {\n\t\t\t\t\t\tdisplay: inline-block;\n\t\t\t\t\t\tvertical-align: top;\n\t\t\t\t\t\ttext-align: left;\n\t\t\t\t\t}\n\t\t\t\t\t.ProgressDots .dc {\n\t\t\t\t\t\tdisplay: inline-block;\n\t\t\t\t\t\toverflow: hidden;\n\t\t\t\t\t\twidth: 1%;\n\t\t\t\t\t\theight: 100%;\n\t\t\t\t\t\tanimation: ProgressDots infinite ".concat(speed, "s linear;\n\t\t\t\t\t}\n\t\t\t\t\t\n\t\t\t\t\t@keyframes ProgressDots {\n\t\t\t\t\t\tfrom {\n\t\t\t\t\t\t\twidth: 1%;\n\t\t\t\t\t\t}\n\t\t\t\t\t\tto {\n\t\t\t\t\t\t\twidth: 100%;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t") }), (_c = props.text) !== null && _c !== void 0 ? _c : '', (0, jsx_runtime_1.jsx)("span", __assign({ className: 'ProgressDots' }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'dc' }, { children: ''.padEnd(count, '.') })) }))] }));
}
exports["default"] = ProgressDots;
