import Avatar from 'components/User/Avatar';
import React, {ReactElement} from 'react';
import countryList from 'react-select-country-list';
import IUser from 'definitions/IUser';
import {T} from '@tolgee/react';
import CountryFlagImage from "./CountryFlagImage";

interface Props {
	user: IUser,
	displayName?: boolean,
	displayNationality?: boolean,
	isWithUpload?: boolean,
}

const defaultProps = {
	displayName: true,
	displayNationality: true,
	isWithUpload: false,
}

export default function AccountBox(props: Props): ReactElement {
	const {user, displayName, displayNationality, isWithUpload} = {...defaultProps, ...props};

	return (
		<div className="box AccountBox">
			<div className="AccountBox__Info">
				<Avatar withUpload={isWithUpload} />

				{displayName && <div className="AccountBox__Info__Name">{user.firstname} {user.lastname}</div>}

				{displayNationality && user.nationality &&
					<div className="AccountBox__Info__Country">
						<CountryFlagImage country={user.nationality ? user.nationality.toLowerCase() : undefined} alt='Nationality flag' title={user.nationality} /> <span>{countryList().getLabel(user.nationality)}</span>
					</div>
				}
			</div>
		</div>
	);
}
