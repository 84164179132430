import {Document, Page, Text, View, Link, Image, StyleSheet, Font} from "@react-pdf/renderer";
import React, {ReactElement} from 'react';
import countryList from 'react-select-country-list';
import logo from 'scss/assets/logo_horizontal.png';
import IUser from 'definitions/IUser';
import fontReg from 'scss/font/Josefin_Sans/JosefinSans-Regular.ttf';
import fontBold from 'scss/font/Josefin_Sans/JosefinSans-Bold.ttf';

Font.register({ family: 'JosefinSans', fonts: [
		{ src: fontReg },
		{ src: fontBold, fontWeight: 500 },
]});

const styles = StyleSheet.create({
	pageCol: {
		flexDirection: 'column',
		backgroundColor: 'white',
		padding: 30,
		fontFamily: 'JosefinSans',
		fontSize: 12
	}
});

interface Props {
	user: IUser
}

export function PassportPdfDocument(props: Props): ReactElement {
	const now: Date = new Date();
	
	return (
		<Document>
			<Page size="A4" style={styles.pageCol}>
				<View style={{fontSize: 10, flexDirection: 'row', marginBottom: 40}}>
					<View style={{flex: '1 1 40%'}}>
						<Image src={logo} style={{width: 155, height: 50, marginBottom: 20}}/>
					</View>
					<View style={{marginLeft: 'auto'}}>
						<Text style={{fontWeight: 500, marginBottom: 8}}>MyEuroloppet</Text>
						<View style={{fontSize: 9, marginBottom: 8}}><Link src="https://www.euroloppet.eu" style={{fontWeight: 500}}>www.euroloppet.com</Link></View>
						<Text style={{fontSize: 9}}>{now.toLocaleDateString()}</Text>
					</View>
				</View>
				
				<View style={{marginBottom: 20, paddingLeft: 30}}>
					<View style={{position: 'relative'}}>
						<Image src='pdf_bgd.png' style={{width: 300, height: 250, position: 'absolute', left: 200, top: 40, zIndex: 3}} />
						<Image src='passport.png' style={{width: 250, height: 300, position: 'absolute', left: 0, top: 0, zIndex: 2}} />
						{props.user.passport && <View style={{textAlign: 'left', position: 'absolute', left: 260, top: 70, zIndex: 1}}>
							<Text style={{fontWeight: 'normal', fontSize: 20, marginBottom: 2, color: 'rgb(48, 48, 52)'}}>Passport number:</Text>
							<Text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 5}}>{props.user.passport.id}</Text>
							<Text style={{fontSize: 15, marginBottom: 15, color: 'rgb(48, 48, 52)'}}>Valid until: <Text style={{fontWeight: 'bold'}}>{(new Date(props.user.passport.validTo)).toLocaleDateString()}</Text></Text>
							<Text style={{fontWeight: 'normal', fontSize: 15, marginBottom: 5, color: 'rgb(48, 48, 52)'}}>Owner:</Text>
							<View style={{flexDirection: 'row'}}>
								<View style={{position: 'relative', width: 140}}>
									<Text style={{fontWeight: 'bold', fontSize: 13, marginBottom: 5}}>{props.user.degree} {props.user.firstname}</Text>
									<Text style={{fontWeight: 'bold', fontSize: 13, marginBottom: 5}}>{props.user.lastname} </Text>
									<Text style={{fontWeight: 'bold', fontSize: 13, marginBottom: 5}}>{(new Date(props.user.dob ?? '')).toLocaleDateString()}</Text>
									{props.user.nationality && <Text style={{fontWeight: 'bold', fontSize: 13, marginBottom: 5}}><Text>{countryList().getLabel(props.user.nationality)}</Text></Text>}
								</View>
								{props.user.avatar &&
									<View style={{width: 60}}>
										<Image src={props.user.avatar} style={{width: 60, height: 60}} />
									</View>
								}
							</View>
						</View>}
					</View>
				</View>
			</Page>
		</Document>
	);

}
