"use strict";
exports.__esModule = true;
exports.ResultFlags = void 0;
var ResultFlags;
(function (ResultFlags) {
    ResultFlags[ResultFlags["IMPORT_BACK_TRACED"] = 1] = "IMPORT_BACK_TRACED";
    ResultFlags[ResultFlags["IMPORT_PASSPORT_INVALID"] = 2] = "IMPORT_PASSPORT_INVALID";
    ResultFlags[ResultFlags["IMPORT_INVALID_ACCEPTED"] = 4] = "IMPORT_INVALID_ACCEPTED";
    ResultFlags[ResultFlags["UPDATED"] = 8] = "UPDATED";
    ResultFlags[ResultFlags["IMPORT_DATA_MISMATCH"] = 16] = "IMPORT_DATA_MISMATCH";
    ResultFlags[ResultFlags["IMPORT_DATA_MISMATCH_ACCEPTED"] = 32] = "IMPORT_DATA_MISMATCH_ACCEPTED";
    ResultFlags[ResultFlags["IMPORT_NOT_FOUND"] = 64] = "IMPORT_NOT_FOUND";
})(ResultFlags || (ResultFlags = {}));
exports.ResultFlags = ResultFlags;
