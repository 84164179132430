import {T, useSetLanguage} from '@tolgee/react';
import Topmenu from 'components/Topmenu';
import {useUserContext} from 'hooks/useUserContext';
import {ProgressDots} from 'myeuroloppet-shared';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';
import {AppContext} from 'services/AppContextProvider';
import {getBrowserLocale} from 'services/Utils';
import {logout} from 'services/authentication';

export default function WithNavigation(): ReactElement {
	const {authUser, user, loading, error} = useUserContext();
	const appContext = useContext(AppContext);
	const location = useLocation();
	const page = location.pathname.replace('/', '');
	const lang = getBrowserLocale(true) || 'en';
	const setLang = useSetLanguage();
	const [isRacerActionAllowed, setIsRacerActionAllowed] = useState<boolean>(false);

	useEffect(() => {
		setLang(lang);
	}, []);

	useEffect(() => {
		const {settings, activeSeason} = appContext.state;
		if (!user || !user.isRacerLastSeason || !settings || !activeSeason) return;

		const trophyWithVoucher = user.racerTrophies?.find((ra) => ra.seasonId === (activeSeason - 1) && ra.completed && ra.freeRace);
		if (user.isRacerLastSeason && (settings.racerAction || trophyWithVoucher)) {
			setIsRacerActionAllowed(true);
		}
	}, [user, appContext.state.settings]);

	if (loading) {
		return (
			<div className="Page Page--simple Page--loading">
				<div className="Page__Container">
					<div className='box'>
						<p><T>loading</T><ProgressDots count={3} /></p>
					</div>
				</div>
			</div>
		)
	}
	
	if (!authUser || !authUser.emailVerified) {
		return <Navigate to="/login" state={{from: location}} replace={true}/>;
	}

	if (!user && error) {
		return (
			<div className="Page Page--simple Page--error">
				<div className="Page__Container">
					<div className='box'>
						<p><T>Error loading the user data. Please, try again or contact support.</T></p>
						<button className="Button Button--white" onClick={logout}>Logout</button>
					</div>
				</div>
			</div>
		)
	}

	if (!user && authUser) {
		return <Navigate to="/login" state={{from: location}} replace={true}/>;
	}

	return (
		<div className={`Page`}>
			<div className="MyLogo">My.Euroloppet</div>

			{user && <Topmenu page={page} email={user?.email} cmia={user.cmia ?? false} isRacerActionAllowed={isRacerActionAllowed} />}

			<div className="Page__Container">
				{user && <Outlet />}
			</div>
		</div>
	)
}
