import {useEffect} from 'react';
import {FieldElement, FieldError, useFormState, Control} from 'react-hook-form';

export default function useDisplayFormErrors(control: Control) {
	const formState = useFormState({control});
	const {errors} = formState;

	useEffect(() => {
		function clearError(name: string) {
			let field = document.getElementsByName(name)[0];
			if ('classList' in field) {
				field.classList.remove('error');
			}
			if (field.parentNode) {
				field.parentNode.querySelectorAll('.error').forEach((el) => el.remove());
			}
		}

		function addError(type: string, ref: FieldElement, message?: string) {
			if ('classList' in ref) {
				ref.classList.add('error');
				let err = document.createElement('p');
				err.className = 'error';
				err.appendChild(document.createTextNode(message as string));
				ref.parentNode?.appendChild(err);
			}
		}

		for (let i in control._fields) {
			let val = control._fields[i];
			if (val?.hasOwnProperty('_f')) {
				clearError(i);
			}
			else {
				for (let j in val) {
					clearError(i + '.' + j);
				}
			}
		}

		if (!errors) return;
		for (let i in errors) {
			let e:FieldError = errors[i];
			if (!e) {
			}
			else if (e.ref) {
				addError(e.type, e.ref, e.message)
			}
			else {
				for (const prop in e) {
					const f = e[prop as keyof FieldError] as FieldError;
					if (f.ref) {
						addError(f.type, f.ref, f.message);
					}
				}
			}
		}
	}, [formState])

	return formState;
}
