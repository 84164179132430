import IUser from 'definitions/IUser';
import {doc, getDoc, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from 'services/firebase';
import {AppError, ErrorCodes, IRace, IRacerAction, ISeason, ISettings, ISpecialTrophy} from 'myeuroloppet-shared';
import {collectionWithConverter} from 'services/firestoreConverter';

export const users = collectionWithConverter<IUser>(db, 'users');

export const specialTrophies = collectionWithConverter<ISpecialTrophy>(db, 'specialTrophies');

export const seasons = collectionWithConverter<ISeason>(db, 'seasons');

export const races = collectionWithConverter<IRace>(db, 'races');

export const racerAction = collectionWithConverter<IRacerAction>(db, 'racerAction');

export const settings = collectionWithConverter<ISettings>(db, 'settings');

export const getSpecialTrophy = async (id: string): Promise<ISpecialTrophy | null> => {
	const res = await getDoc(doc(specialTrophies, id));
	
	if (res.exists()) {
		return res.data() as ISpecialTrophy;
	} else {
		return null;
	}
}

export const getActiveSeasonId = async (): Promise<number> => {
	const now = new Date();
	const seasonsSnap = await getDocs(query(seasons, where('todate', '>', now), orderBy('todate', 'asc')));

	for (const s of seasonsSnap.docs) {
		const season = s.data();
		if (season.fromdate.toDate() < now) {
			return season.id;
		}
	}

	throw new AppError(ErrorCodes.notFound, 'There\'s no active season.');
}
