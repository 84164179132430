"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoCamera() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ className: 'IcoCamera', viewBox: '0 0 40 31', xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "xMidYMid meet", version: "1.0" }, { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("path", { d: "m34.56778,4.18192l-6.46707,0l0,-2.64567l-16.16767,0l0,2.64567l-6.46707,0c-1.9039,0 -3.4491,1.58035 -3.4491,3.52756l0,18.29921c0,1.94721 1.5452,3.52756 3.4491,3.52756l29.1018,0c1.9039,0 3.4491,-1.58035 3.4491,-3.52756l0,-18.29921c0,-1.94721 -1.5452,-3.52756 -3.4491,-3.52756zm-14.55176,23.36831c-5.76345,0 -10.45078,-4.79572 -10.45078,-10.69027c0,-5.89455 4.68733,-10.69203 10.45078,-10.69203s10.45423,4.79748 10.45423,10.69203c0,5.89455 -4.6925,10.69027 -10.45423,10.69027zm0,-17.8565c-3.86989,0 -7.00513,3.20831 -7.00513,7.16447c0,3.95792 3.13696,7.16447 7.00513,7.16447c3.86989,0 7.00513,-3.20655 7.00513,-7.16447c0.00172,-3.95616 -3.13351,-7.16447 -7.00513,-7.16447z" }) }) })));
}
exports["default"] = IcoCamera;
