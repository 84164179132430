"use strict";
exports.__esModule = true;
exports.sortBySeason = exports.removeById = exports.findIndexById = exports.findById = void 0;
function findById(id, items) {
    return items.filter(function (item) { return item.id === id; })[0];
}
exports.findById = findById;
function findIndexById(id, items) {
    return items.findIndex(function (item) { return item.id === id; });
}
exports.findIndexById = findIndexById;
function removeById(id, items) {
    return items.filter(function (item) { return item.id !== id; });
}
exports.removeById = removeById;
function sortBySeason(a, b) {
    var _a, _b;
    var aSeason = 0;
    var bSeason = 0;
    if ('seasonTo' in a && a.seasonTo) {
        aSeason = a.seasonTo;
    }
    else if ('seasonFrom' in a) {
        aSeason = (_a = a.seasonFrom) !== null && _a !== void 0 ? _a : 0;
    }
    else if ('seasonId' in a) {
        aSeason = a.seasonId;
    }
    if ('seasonTo' in b && b.seasonTo) {
        bSeason = b.seasonTo;
    }
    else if ('seasonFrom' in b) {
        bSeason = (_b = b.seasonFrom) !== null && _b !== void 0 ? _b : 0;
    }
    else if ('seasonId' in b) {
        bSeason = b.seasonId;
    }
    return aSeason > bSeason ? 1 : (aSeason < bSeason ? -1 : 0);
}
exports.sortBySeason = sortBySeason;
