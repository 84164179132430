"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoHome() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ className: 'IcoHome', viewBox: '0 0 40 40', xmlns: "http://www.w3.org/2000/svg", version: "1.0", preserveAspectRatio: "xMidYMid meet" }, { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("path", { d: "m38.76594,19.96224l-4.92533,-4.97686l0,-11.88628l-4.2224,0l0,7.6197l-9.85227,-9.95535l-19,19.19879l4.5232,-0.1089l0,19.41008l28.75254,0l0,-19.30118l4.72426,0zm-19,16.52506l-9.38258,0l0,-11.37754l9.38258,0l0,11.37754zm9.11556,-4.87609l-6.16551,0l0,-6.56972l6.16551,0l0,6.56972z" }) }) })));
}
exports["default"] = IcoHome;
