import Partners from 'components/Partners';
import Passport from 'components/Passport/Passport';
import PassportNone from 'components/Passport/PassportNone';
import Trophies from 'components/Trophies/Trophies';
import AccountBox from 'components/User/AccountBox';
import UserResults from 'components/UserResults';
import {useUserContext} from 'hooks/useUserContext';
import React, {ReactElement, useContext} from "react";
import {AppContext} from 'services/AppContextProvider';
import {T} from '@tolgee/react';

export default function DashboardPage(): ReactElement {
	console.log('<< DashboardPage >>');
	const {user} = useUserContext();
	const appContext = useContext(AppContext);

	return (
		<>
			{user &&
				<div className='DashboardPage'>
					<section className="ProfileSection">
						<AccountBox user={user} />
				  </section>
					
					<section className="PassportSection">
						<div className="box">
							<h2><T>Euroloppet pass</T></h2>

							{user.passport && <Passport dbUser={user} dispatch={appContext.dispatch} />}
							{!user.passport && <PassportNone dbUser={user} dispatch={appContext.dispatch} />}
						</div>
					</section>

					<section className='PartnersSection'>
						<Partners />
					</section>
					
					<section className='TrophiesSection'>
						<Trophies />
					</section>

					<section className='UserResults'>
						<UserResults />
					</section>
				</div>
			}
		</>
	);
}
