import AccountConnector from 'components/User/AccountConnector';
import {httpsCallable} from 'firebase/functions';
import {useUserContext} from 'hooks/useUserContext';
import {ErrorCodes, IcoHelp, ProgressDots} from 'myeuroloppet-shared';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {sendEmailVerification, sendPasswordResetEmail} from 'firebase/auth';
import {T, useSetLanguage, useTranslate} from '@tolgee/react';
import {AppContext} from 'services/AppContextProvider';
import {auth, functions} from 'services/firebase';
import {signInWithGoogle, signInWithPassword, registerWithPassword, checkEmailExists, logout } from 'services/authentication';
import {getBrowserLocale} from 'services/Utils';

enum SignupStep {
	LOADING = 'loading',
	REGISTERING = 'registering',
	ENTER_EMAIL = 'enter',
	ENTER_PASSWORD = 'login',
	SIGNIN_WITH_GOOGLE = 'signWithGoogle', // this state is to identify a Google signin is in progress
	CREATE_PASSWORD = 'createPassword', // standard password registration = create Auth, create User, connect
	ENTER_MYPASSPORT = 'createWithPassport',
	CONNECT_WITH_GOOGLE = 'connectByGoogle', // standard Google registration = (Auth created automatically), create User, connect ...edit: rozsireno na vsechny nove registrace, ktere jeste nemaji db user(nejen google)
	VERIFY_EMAIL = 'verifyEmail',
	ERROR = 'error',
}

export default function LoginPage(): ReactElement {
	//console.log('<<LoginPage>>')

	const stepsWithRegisterHeader = [SignupStep.CREATE_PASSWORD, SignupStep.ENTER_MYPASSPORT, SignupStep.SIGNIN_WITH_GOOGLE];
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [agreeError, setAgreeError] = useState<string | null>(null);
	const [passwordError, setPasswordError] = useState<string | null>(null);
	const [connectUserId, setConnectUserId] = useState<string | null>(null);
	const [skipConnect, setSkipConnect] = useState(false);
	const [searching, setSearching] = useState(false);
	const [step, setStep] = useState<SignupStep>(SignupStep.LOADING);
	const [fetchCount, setFetchCount] = useState(0);
	const navigate = useNavigate();
	const {dispatch} = useContext(AppContext);
	const {authUser, loading, dispatchRefresh, user, error} = useUserContext();
	const location = useLocation();
	const t = useTranslate();
	const lang = getBrowserLocale(true) || 'en';
	const setLang = useSetLanguage();
	const appContext = useContext(AppContext);

	const passErrorText = t('Password must have min 6 characters.');
	const agreeErrorText = t('Must agree with terms.');
	const signupFailedErrorText = t('Signup failed.');

	const showInfo = () =>  {
		appContext.dispatch({type: 'flash', payload: {
				type: 'info',
				message: <div className='InfoMessage'><IcoHelp /><h3>Start with your Euroloppet passport.</h3>
					<div>
						<div>
							<T>If you already have an Euroloppet passport, type it's number now during the registration and it will be connected to your account with all your previous results and trophies.</T>
						</div>
						<ul>
							<li>Type the Passport number</li>
							<li>If you register with another email or there is no email entered in your older passport, verify by entering your date of birth.</li>
						</ul>
					</div>
				</div>
			}});
	}

	const skipChange = (val: boolean) => {
		setSkipConnect(val);
	}

	const handleSignIn = async (signFnc: () => Promise<string | null | void>) => {
		try {
			await signFnc();
		}
		catch (e: any) {
			let message = t('Sign in failed.');

			if (e.code) {
				if (e.code === ErrorCodes.auth_wrongPassword) {
					message = t('Wrong credentials. Sign in failed.');
				}
			}
			dispatch({type: 'barMessage', payload: {type: 'error', message: message, autoOff: 3000}});
		}
	}

	const signInWithGoogleHandler = async () => {
		await handleSignIn(signInWithGoogle);
	}

	const onEnterTrigger = async (e: React.KeyboardEvent<HTMLInputElement>, signFnc: () => void) => {
		if (e.key === 'Enter') {
			await signFnc();
		}
	}

	const checkEmail = async () => {
		if (!email) return;

		try {
			const exists = await checkEmailExists(email);

			if (exists) {
				setStep(SignupStep.ENTER_PASSWORD);
			}
			else {
				setStep(SignupStep.CREATE_PASSWORD);
			}
		}
		catch (e) {}
	}

	const continueWithPassword = () => {
		const agreeCheckbox: HTMLInputElement = document.getElementById('agreement') as HTMLInputElement;
		if (!password || password.length < 6) {
			setPasswordError(passErrorText);
		}
		else if (!agreeCheckbox || !agreeCheckbox.checked) {
			setPasswordError(null);
			setAgreeError(agreeErrorText);
		}
		else {
			setStep(SignupStep.ENTER_MYPASSPORT);
			setPasswordError(null);
			setAgreeError(null);
		}
	}

	const signInWithPasswordHandler = async () => {
		await handleSignIn(() => signInWithPassword(email, password));
	}

	const registerWithPasswordHandler = async () => {
		if (!password) return;

		setStep(SignupStep.REGISTERING);

		try {
			await registerWithPassword(email, password);
		}
		catch(e: any) {
			let message = e.message || signupFailedErrorText;
			if (e.message && e.message === 'INVALID_EMAIL') {
				message = t('Invalid email. Signup failed.');
			}
			dispatch({type: 'barMessage', payload: {type: 'error', message: message, autoOff: 3000}});

			setStep(SignupStep.ENTER_EMAIL);
			return;
		}

		finishSignup();
	};

	const finishSignup = async () => {
		setStep(SignupStep.REGISTERING);

		const args: {lang?: string, userId?: string} = {lang: getBrowserLocale()};
		if (connectUserId) {
			args.userId = connectUserId;
		}

		try {
			await httpsCallable<{lang?: string, userId?: string}>(functions, 'createUser')(args);
		} catch (e) {
			setStep(SignupStep.CONNECT_WITH_GOOGLE);
			dispatch({type: 'barMessage', payload: {type: 'error', message: signupFailedErrorText, autoOff: 3000}});
		}

		dispatchRefresh();
		dispatch({type: 'barMessage', payload: {type: 'success', message: t('Registration successful.'), autoOff: 3000}});
	};

	const resetPassword = async () => {
		await sendPasswordResetEmail(auth, email, {url: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/dashboard'});
		dispatch({type: 'flash', payload: {type: 'info', message: <h3>{t('An email with instructions was sent to provided email address.')}</h3>}});
	};

	const resendEmailVerification = async () => {
		if (!authUser) return;

		const link = document.getElementById('ResendEmailLink');
		let y;
		if (link) {
			y = link.offsetTop - link.clientHeight/2;
		}

		try {
			await sendEmailVerification(authUser, {url: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/dashboard'});
			setFetchCount(0);
			dispatch({type: 'barMessage', payload: {type: 'success', message: t('An email was sent to provided email address.'), autoOff: 3000, y: y}});
		}
		catch (e) {
			dispatch({type: 'barMessage', payload: {type: 'error', message: t('Too many email requests. Wait at least 1 minute.'), autoOff: 3000, y: y}});
		}
	}

	const logoutHandler = () => {
		logout();

		setEmail('');
		setPassword('');
		setConnectUserId(null);
		setSkipConnect(false);

		setStep(SignupStep.ENTER_EMAIL);
	}

	/** setLang at the begining */

	useEffect(() => {
		setLang(lang);
	}, []);

	/** check auth state changes */

	useEffect(() => {
		//console.log('LoginPage - AUTH/USER CHANGE')

		if (loading && step !== SignupStep.REGISTERING) {
			//console.log('SET LOADING');
			setStep(SignupStep.LOADING);
			return;
		}

		if (error) {
			setStep(SignupStep.ERROR);
			return;
		}

		if (authUser && user) {
			if (authUser.emailVerified) {
				navigate(location.state?.path || '/dashboard');
			} else {
				setStep(SignupStep.VERIFY_EMAIL);
			}
			return;
		}

		if (step === SignupStep.REGISTERING) {
			//console.log('LoginPage ...still registering');
			return;
		}

		if (authUser) {
			//console.log('AUTH/USER - NO USER');
			/** user === null (not 'undefined') is state of signup  */
			if (user === null) {
				if (authUser.email) {
					setEmail(authUser.email);
				}
				setStep(SignupStep.CONNECT_WITH_GOOGLE);
			}
		}
		else {
			setStep(SignupStep.ENTER_EMAIL);
		}
	}, [authUser, user, loading]);

	/** try to refresh checking email was verified, stop after N(N=20) attempts */

	useEffect(() => {

		if (step === SignupStep.VERIFY_EMAIL && fetchCount < 20) {
			const ft = setInterval(() => {
				setFetchCount(() => fetchCount + 1);
				authUser?.reload().then(() => {
					if (authUser.emailVerified) {
						clearInterval(ft);
						navigate(location.state?.path || '/dashboard');
					}
				})
			}, 5000);

			return () => clearInterval(ft);
		}

		const inputs = document.getElementsByClassName('LoginPage__FocusInput');
		if (inputs && inputs.length) {
			(inputs[0] as HTMLInputElement).focus();
		}
	}, [step]);
	
	return (
		<div className={`Page LoginPage ${step}`}>
			<div className='Page__Container'>
				{!stepsWithRegisterHeader.includes(step) && <h1><T>Enter MyEuroloppet</T></h1>}
				{stepsWithRegisterHeader.includes(step) && <h1><T>Register to MyEuroloppet</T></h1>}

				<div className='box'>
					{step === SignupStep.LOADING && <div className='LoginPage__Loading'><T>loading</T><ProgressDots /></div>}
					{step === SignupStep.REGISTERING && <div className='LoginPage__Loading'><T>registering</T><ProgressDots /></div>}
					{step === SignupStep.ERROR &&
						<div className='LoginPage__Loading'>
							<p><T>Error loading the user data. Please, try again or contact support.</T></p>
							<button className='Button Button--white' onClick={logoutHandler}>Logout</button>
						</div>
					}

					{step === SignupStep.ENTER_EMAIL &&
					<>
						<input type='email' autoComplete='email' className='LoginPage__FocusInput' value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => onEnterTrigger(e, checkEmail)} placeholder={t('E-mail Address')} />
						<button className='Button' onClick={checkEmail}><T>Continue</T></button>

				    <div className='GoogleButton'>
					    <div className='GoogleButton__Button' onClick={signInWithGoogleHandler}><span className='icon'></span><span className='label'><T>Sign in with Google</T></span></div>
				    </div>
					</>
					}
					
					{step === SignupStep.ENTER_PASSWORD &&
				  <>
				    <input type='password' value={password} className='LoginPage__FocusInput' onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => onEnterTrigger(e, signInWithPasswordHandler)} placeholder={t('Password')}/>
					  <button className='Button' onClick={signInWithPasswordHandler}><T>Sign in</T></button>

			      <a href='#' className='reset' onClick={resetPassword}><T>Forgot Password?</T></a>
						<button className='Button Button--transparent Button--back' onClick={() => setStep(SignupStep.ENTER_EMAIL)}>&lt;&lt; {t('back')}</button>
				  </>
					}
					
					{step === SignupStep.CREATE_PASSWORD &&
				  <>
				    <div className='LoginPage__Register__Headline'>
					    <p className='LoginPage__Register__Headline__General'><T keyName='register_new_account_headline' parameters={{email: email, span: <span />}}>Register new account in two steps.</T></p>
					    <p className='LoginPage__Register__Headline__Step1'><T keyName='register_new_account_step1' parameters={{b: <b /> }}>At first create password for your account.</T></p>
				    </div>
					  <input type='password' value={password} className='LoginPage__FocusInput' onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => onEnterTrigger(e, continueWithPassword)} placeholder={t('Password')}/>
				    {passwordError && <p className='LoginPage__Register__Error error'>{passwordError}</p>}

					  <div className='LoginPage__Register__Agreement'>
						  <p><T>And confirm the privacy policy</T></p>
						  <label><input type='checkbox' id='agreement' /> <span><T>I agree that my data will be saved in the Euroloppet database.</T></span></label>
						  <div>(<T>Full text of</T> <a target='_blank' href='https://www.euroloppet.com/cz/service/privacy-policy.html'><T>Privacy Policy</T>)</a></div>
						  {agreeError && <p className='LoginPage__Register__Error error'>{agreeError}</p>}
					  </div>

					  <button className='Button' onClick={continueWithPassword}><T>Continue</T></button>

					  <button className='Button Button--transparent Button--back' onClick={() => setStep(SignupStep.ENTER_EMAIL)}>&lt;&lt; {t('back')}</button>
				  </>
					}

					{(step === SignupStep.ENTER_MYPASSPORT || step === SignupStep.CONNECT_WITH_GOOGLE) &&
						<>
							<div className='LoginPage__Register__Headline'>
								<p className='LoginPage__Register__Headline__Step2'>
									<T keyName='register_new_account_step2' parameters={{b: <b />}}>In the last step insert the Passport Number, if you already have Euroloppet Passport.</T>
								</p>
								<p className='LoginPage__Register__Headline__Step2Info'>
									<T>It will connect your passport to your account with all previous results and achievements.</T>
									<span className='LoginPage__Register__Headline__Step2Info__More' title='More info' onClick={showInfo} > <T>More info...</T></span>
								</p>
							</div>

							{<AccountConnector isSkipped={skipConnect} email={email} onUserFound={setConnectUserId} onSearching={(state: boolean) => setSearching(state)} />}

							{!connectUserId && !searching &&
								<div className='LoginPage__Register__Skip'>
									<label><input type='checkbox' name='skip' onChange={(e) => setSkipConnect(e.currentTarget.checked)}/> <span><T>Skip this step and start with blank new account.</T></span></label>
								</div>
							}

							{step === SignupStep.ENTER_MYPASSPORT &&
								<>
									<button className='Button' onClick={registerWithPasswordHandler} disabled={!skipConnect && !connectUserId}><T>Create account</T></button>

									<button className='Button Button--transparent Button--back Button--back--onConnect' onClick={() => setStep(SignupStep.CREATE_PASSWORD)}>&lt;&lt; {t('back')}</button>
								</>
							}

							{step === SignupStep.CONNECT_WITH_GOOGLE &&
								<>
									<button className='Button' onClick={finishSignup} disabled={!skipConnect && !connectUserId}><T>Finish signup</T></button>
								</>
							}

						</>
					}
					
					{step === SignupStep.VERIFY_EMAIL &&
				  <div className='VerifyEmail'>
					  <div>
							<p><T>An verification email was sent to your address.</T></p>
						  <h3><T>Open the email and click on the verification link in it.</T></h3>
							<p>&nbsp;</p>
					  </div>
					  <p><button className='ButtonLink' onClick={resendEmailVerification} id='ResendEmailLink'><T>Resend verification</T></button></p>
					  <p><button className='ButtonLink' onClick={logoutHandler}><T>Logout</T></button></p>
				  </div>
					}
					
				</div>
			</div>
		</div>
	);
}
