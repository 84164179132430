import DiplomaPdfDownload from 'components/Trophies/DiplomaPdfDownload';
import TrophyProgress from 'components/Trophies/TrophyProgress';
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import React, {ReactElement} from 'react';
import {IChampionUser, IChampionUserItem} from 'myeuroloppet-shared';

export default function TrophyChampion (props:{diplomaProps: ITrophyDiplomaProps, trophy: IChampionUser}): ReactElement {
	const doneRaces: string[] = (props.trophy.items ?? []).map((i: IChampionUserItem) => i.raceId);
	const doneSeries: number[] = props.trophy.series;
	
	return (
		<div className={`Trophy Trophy--champion ${props.trophy.completed ? 'Trophy--completed' : ''}`}>
			<div className='Trophy__Cont'>
				<div className='Trophy__Image'><img src='cup3.png' alt='Champion Cup' /></div>
				<TrophyProgress count={8} completed={false} doneRaces={doneRaces} doneSeries={doneSeries} />
			</div>
			<h4>{`Champion ${props.trophy.seasonTo ?? (props.trophy.seasonFrom + '-')}`}</h4>
			{props.trophy.completed && <DiplomaPdfDownload {...props.diplomaProps} />}
		</div>
	)
}