import {IcoCross} from 'myeuroloppet-shared';
import React, {useState, useEffect, HTMLAttributes} from 'react';


interface OwnProps {
	onClose: () => void,
	enableBgClick?: boolean,
	autoOff?: number,
	y?: number
}
type Props = OwnProps & HTMLAttributes<HTMLElement>

export default function Overlay (props: Props) {
	const [minimized, setMinimized] = useState(1);
	const {className, onClose, enableBgClick = true, autoOff} = props;
	const styleAttribute: React.CSSProperties = props.y ? {position: 'absolute', top: props.y} : {};

	const onBgClick = () => {
		if (enableBgClick) {
			setMinimized(1);
		}
	};

	const onTransitionEnd = () => {
		console.log("transitionend");
		if (minimized) {
			onClose();
		}
	}

	useEffect(() => {
		setTimeout(() => setMinimized(0), 10);
	}, []);

	useEffect(() => {
		console.log('useeffect', autoOff)
		if (autoOff) {
			const t = setTimeout(() => setMinimized(1), autoOff);
			return () => clearTimeout(t);
		}
	}, [autoOff]);

	return (
	  <div className={`Overlay ${className || ''} ${minimized ? 'Overlay--minimized' : ''}`}>
			<div className="Overlay__BackGround" onClick={onBgClick} />
		  <div className="Overlay__Panel" onTransitionEnd={onTransitionEnd} style={styleAttribute}>
			  <button className="CloseButton" onClick={() => setMinimized(1)}><IcoCross /></button>
			  {props.children}
		  </div>
	  </div>
	)
}