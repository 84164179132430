import {ReactElement} from 'react';


interface Props {
	maxWidth?: number,
	maxHeight?: number,
	onSubmit: (imageData: Blob) => void
}

export default function ImageUpload(props:Props): ReactElement {
	const MAX_WIDTH = 1920;
	const MAX_HEIGHT = 1080;
	
	const changeHandler = (event:React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}
		
		let reader = new FileReader();
		reader.onload = (e: ProgressEvent<FileReader>) => {
			if (!e.target || !e.target.result) {
				return;
			}

			/** construct the image to check the size */
			const image = new Image();
			image.src = e.target.result as string;
			image.onload = () => {
				const maxHeight = props.maxHeight ? Math.min(props.maxHeight, MAX_HEIGHT) : MAX_HEIGHT;
				const maxWidth = props.maxWidth ? Math.min(props.maxWidth, MAX_WIDTH) : MAX_WIDTH;

				let w: number = image.width;
				let h: number = image.height;
				if (w > maxWidth || h > maxHeight) {
					const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);
					w = image.width * ratio;
					h = image.height * ratio;

					w = Math.floor(w);
					h = Math.floor(h);
				}

				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				canvas.width = w;
				canvas.height = h;

				if (!context) return;
				context.drawImage(image, 0, 0, w, h);
				canvas.toBlob((blob) => { if (blob) props.onSubmit(blob); });
			}
		};

		reader.readAsDataURL(event.target.files[0]);
	}
	
	return (
			<input className='ImageUpload' type="file" onChange={changeHandler} title='upload image' />
	)
	
}