"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoTrash() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 20 20', className: 'IcoTrash' }, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { id: "svg_3", d: "m6.94714,2.13799c-0.4,0.8 -1.6,1.5 -2.6,1.7c-2.2,0.45 -2.475,2.2375 -1.15,2.325c1.325,0.0875 3.6625,0.15 6.7625,0.125c3.1,-0.025 4.9,-0.0125 6.16875,0.0375c1.26875,0.05 0.86875,-1.775 -0.44375,-2.275c-1.3125,-0.5 -2.0375,-1.0125 -2.4375,-1.8125c-0.7,-1.9 -5.2,-2 -6.3,-0.1zm4.4625,1.6375c-0.3,0.3 -1.88749,0.3375 -2.58749,0.0375c-0.8,-0.3 0.125,-0.975 1.225,-0.975c1.1,-0.1 1.7625,0.6375 1.3625,0.9375z" }), (0, jsx_runtime_1.jsx)("path", { id: "svg_4", d: "m3.84714,13.43799l0.3,5.8l6,0l6,0l0.3,-5.8l0.3,-5.7l-6.6,0l-6.6,0l0.3,5.7zm3.8,0.3c-0.0625,3.45 -0.4,4 -1,4c-0.5,0 -1,-0.6125 -1,-4c0,-3.3875 0.5,-4 1,-4c0.6,0 1.0625,0.55 1,4zm2.5125,3.95c-0.825,0.1125 -0.8375,-1.7625 -0.8375,-4.2625c0,-2.5 0.2,-3.625 0.9,-3.55c0.7,0.075 0.91563,0.85 0.86875,3.725c-0.04688,2.875 -0.10625,3.975 -0.93125,4.0875zm4.425,-4.0125c0,2.8875 -0.3375,4.0625 -0.9375,4.0625c-0.5,0 -1,-0.925 -1,-4c0,-3.075 0.5,-4 1,-4c0.6,0 0.9375,1.05 0.9375,3.9375z" })] }) })));
}
exports["default"] = IcoTrash;
