export function getBrowserLocale(languageCodeOnly?: boolean): string|undefined {
	const langOnly = languageCodeOnly || true;

	let locale: string|undefined;

	if (navigator.languages) {
		locale = navigator.languages[0];
	}
	else if (navigator.language) {
		locale = navigator.language;
	}
	else if ('userLanguage' in navigator) {
		locale = navigator['userLanguage' as keyof Navigator] as string;
	}

	if (!locale) return undefined;

	const trimmedLocale = locale.trim();
	return langOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
}
