import {T} from '@tolgee/react';
import IcoMenu from 'components/IcoMenu';
import LoginToken from 'components/LoginToken';
import {IcoHome} from 'myeuroloppet-shared';
import React, {ReactElement, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {logout} from 'services/authentication';

interface Props {
	page: string,
	email: string,
	cmia: boolean,
	isRacerActionAllowed?: boolean,
}

export default function Topmenu(props: Props): ReactElement {
	const {page, email, cmia, isRacerActionAllowed} = props;
	const [isOpened, setIsOpened] = useState(false);

	useEffect(() => {
		setIsOpened(false);
	}, [page]);

	return (
		<nav className="Topmenu">
			<div className="Page__Container">
				<nav className='Topmenu__IconNav' onClick={() => setIsOpened(!isOpened)}><IcoMenu /></nav>
				<div className='Topmenu__Email'>{email}</div>
				<div className={`Topmenu__Content ${isOpened ? 'Topmenu__Content--opened' : ''}`}>
					<Link to="/dashboard" className={`Topmenu__Item ${page === 'dashboard' ? 'Topmenu__Item--active' : ''}`}>
						<span><IcoHome /></span>
					</Link>

					<Link to="/profile" className={`Topmenu__Item ${page === 'profile' ? 'Topmenu__Item--active' : ''}`}>
						<span><T>Profile</T></span>
					</Link>

					{isRacerActionAllowed &&
						<Link to="/racer-action" className={`Topmenu__Item ${page === 'racer-action' ? 'Topmenu__Item--active' : ''}`}>
							<span>Racer 3+1</span>
						</Link>
					}

					<Link to="/contact" className={`Topmenu__Item ${page === 'contact' ? 'Topmenu__Item--active' : ''}`}>
						<span><T>Support</T></span>
					</Link>

					<nav className="Topmenu__Rightmenu">
						<button className="Button Button--white" onClick={() => logout()}><T>Logout</T></button>
						{cmia && <LoginToken />}
						<div className='Topmenu__Rightmenu__Email'>{email}</div>
					</nav>
				</div>
			</div>
		</nav>
	);
}
