import {Document, Page, Text, View, Link, Image, StyleSheet, Font} from "@react-pdf/renderer";
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import React, {ReactElement} from 'react';
import countryList from 'react-select-country-list';
import fontReg from 'scss/font/Josefin_Sans/JosefinSans-Regular.ttf';
import fontBold from 'scss/font/Josefin_Sans/JosefinSans-Bold.ttf';

Font.register({ family: 'JosefinSans', fonts: [
		{ src: fontReg },
		{ src: fontBold, fontWeight: 500 },
]});

const styles = StyleSheet.create({
	pageStyle: {
		backgroundColor: 'white',
		padding: 0,
		fontFamily: 'JosefinSans',
		fontSize: 12
	}
});

export function DiplomaPdfDocument(props: ITrophyDiplomaProps): ReactElement {
	return (
		<Document>
			<Page size="A4" style={styles.pageStyle}>
				<Image fixed src={`diploma/${props.diplomaImage}`} style={{width: '100%', position: 'absolute', left: 0, top: 0, zIndex: 3}} />

				<View fixed style={{textAlign: 'center', position: 'absolute', width: '100%', left: 0, bottom: 160, zIndex: 2}}>
					<Text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 5}}>{props.season}</Text>
					<Text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 5}}>{props.firstname} {props.lastname}</Text>

					{props.nationality && <Text style={{fontWeight: 'bold', fontSize: 13, marginBottom: 5}}><Text>{countryList().getLabel(props.nationality)}</Text></Text>}
				</View>
			</Page>
		</Document>
	);
}
