"use strict";
exports.__esModule = true;
exports.UserResultStatus = void 0;
var UserResultStatus;
(function (UserResultStatus) {
    UserResultStatus["FINISH"] = "FINISH";
    UserResultStatus["FINISH_LOWERCASE"] = "finish";
    UserResultStatus["FINISH_CAPITAL"] = "Finish";
    UserResultStatus["FINISH_FINISHED"] = "Finished";
    UserResultStatus["FINISH_FINISHED_CAPITAL"] = "FINISHED";
    UserResultStatus["FINISH_FINISHED_LOWERCASE"] = "finished";
    UserResultStatus["FINISH_SHORT"] = "FIN";
    UserResultStatus["FINISH_OK_LOWERCASE"] = "ok";
    UserResultStatus["FINISH_OK"] = "OK";
    UserResultStatus["FINISH_RANKED"] = "ranked";
    UserResultStatus["DNS_NYS"] = "NYS";
    UserResultStatus["DNS"] = "DNS";
    UserResultStatus["DNF_UNK"] = "UNK";
    UserResultStatus["DNF"] = "DNF";
    UserResultStatus["DSQ"] = "DSQ";
})(UserResultStatus || (UserResultStatus = {}));
exports.UserResultStatus = UserResultStatus;
