"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoEdit() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: '0 0 30 40', className: 'IcoEdit' }, { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("path", { d: "m27.1033,3.8373l-4.40539,-2.70218c-1.11149,-0.6811 -2.57608,-0.35329 -3.27644,0.73165l-1.73641,2.69714l8.4277,5.16567l1.73778,-2.69588c0.6977,-1.08622 0.36471,-2.51681 -0.74724,-3.19639l0,0zm-24.41536,24.02863l8.42816,5.16562l13.7365,-21.34036l-8.43217,-5.16697l-13.73249,21.34171l0,0zm-1.2874,6.56311l-0.18615,4.85198l4.39863,-2.27051l4.08758,-2.10621l-8.13092,-4.98659l-0.16915,4.51133l0,0l0,0z" }) }) })));
}
exports["default"] = IcoEdit;
