import {doc, getDoc, setDoc, updateDoc, deleteField, UpdateData, query, where, getDocs} from 'firebase/firestore';
import {User} from 'firebase/auth';
import {users} from 'services/firestoreGateway';
import {capitalize, IAddress} from 'myeuroloppet-shared';
import IUser from 'definitions/IUser';
import {normalizeNameToCompare} from 'myeuroloppet-shared/Utils/StringHelpers';

export const getRacerActionRace = (user: IUser, season?: number): string|null|undefined => {
	if (!season || !user.racerTrophies || user.racerTrophies.length === 0) return undefined;

	const trophy = user.racerTrophies.filter((t) => t.seasonId === season);
	if (trophy.length === 0) return undefined;

	return trophy[0].freeRace;
}

export const isRacer = (user: IUser, season?: number): boolean => {
	if (!user.racerTrophies || user.racerTrophies.length === 0) return false;
	if (!season) return true;
	return !!user.racerTrophies.find((r, i) => r.seasonId === season && r.completed);
}

export const getUserById = async (id: string):Promise<IUser | null> => {
	const res = await getDoc(doc(users, id));

	if (res.exists()) {
		return res.data() as IUser;
	} else {
		return null;
	}
}

export const getUserByUid = async (uid:string):Promise<IUser | null> => {
	const userQuery = await getDocs(query(users, where('uid', '==', uid)));
	
	if (!userQuery.empty) {
		return userQuery.docs[0].data() as IUser;
	} else {
		return null;
	}
}
/*
export const createUser = async (user:User) => {
	console.log('create user');
	const docRef = doc(users, user.uid);

	const userData:UpdateData<IUser> = {
		id: user.uid,
		uid: user.uid,
		email: user.email ?? undefined,
		created: user.metadata.creationTime ? Timestamp.fromDate(new Date(user.metadata.creationTime)) : Timestamp.now()
	}
	
	const lang = getBrowserLocale() || 'en';
	if (lang) {
		userData.lang = lang;
	}
	if (user.photoURL) {
		userData.avatar = user.photoURL;
	}
	if (user.phoneNumber) {
		userData.phone = user.phoneNumber;
	}
	
	await setDoc(docRef, userData);
}
*/
/**
 * carefully with immutability. Serialized objects may lose instances like Timestamps.
 */
export const updateUser = async (userId: string, update: UpdateData<IUser>, withCleanup: boolean = false) => {
	/** update normalized names */
	/** update capitalized names */

	if (update.firstname && typeof update.firstname === 'string') {
		update.firstname = capitalize(update.firstname);
		update.firstname_norm = normalizeNameToCompare(update.firstname);
	}

	if (update.lastname && typeof update.lastname === 'string') {
		update.lastname = capitalize(update.lastname);
		update.lastname_norm = normalizeNameToCompare(update.lastname);
	}

	if (withCleanup) {
		/** delete fields with empty string */

		for (const p in update) {
			if (update[p as keyof IUser] === '') {
				update[p as keyof IUser] = deleteField();
			}
		}

		/** delete fields with empty string from address */

		if (update.address) {
			const address: UpdateData<IAddress> = update.address as UpdateData<IAddress>;

			for (const i in address) {
				if (address[i as keyof IAddress] === '') {
					address[i as keyof IAddress] = deleteField();
				}
			}

			update.address = address;
		}
	}

	await updateDoc(doc(users, userId), update);
}

/** remove if not ib use */
export const connectUser = async (userId: string, authUser: User) => {
	await updateUser(userId, {uid: authUser.uid, email: authUser.email ?? undefined});
}
