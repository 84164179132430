"use strict";
exports.__esModule = true;
exports.LogType = void 0;
var LogType;
(function (LogType) {
    LogType["USER"] = "user";
    LogType["USER_CREATED"] = "user_created";
    LogType["USER_UPDATED"] = "user_updated";
    LogType["USER_PASSWORD_RESET"] = "user_password_reset";
    LogType["PASS_CREATED"] = "pass_created";
    LogType["PASS_CONNECTED"] = "pass_connected";
    LogType["PASS_MERGED"] = "pass_merged";
    LogType["PASS_EXTENDED"] = "pass_extended";
    LogType["PASS_EXPIRED"] = "pass_expired";
    LogType["PASS_DEACTIVATED"] = "pass_deactivated";
    LogType["PASS_MODIFIED"] = "pass_modified";
    LogType["ORDER"] = "order";
    LogType["ORDER_CREATED"] = "order_created";
    LogType["ORDER_CREATED_BY_ADMIN"] = "order_created_by_admin";
    LogType["ORDER_UPDATED"] = "order_updated";
    LogType["ORDER_PAID"] = "order_paid";
    LogType["ORDER_CANCELED"] = "order_canceled";
    LogType["ORDER_CANCELED_BY_ADMIN"] = "order_canceled_by_admin";
    LogType["PAYMENT_SUCCESSFUL"] = "payment_successful";
    LogType["PAYMENT_BY_ADMIN"] = "payment_done_by_admin";
    LogType["PAYMENT_FAILED"] = "payment_failed";
    LogType["RACER_TROPHY_CREATED"] = "racer_trophy";
    LogType["RACER_TROPHY_IMPORTED"] = "racer_trophy_imported";
    LogType["RACER_TROPHY_UPDATED"] = "racer_trophy_updated";
    LogType["RACER_TROPHY_COMPLETED"] = "racer_trophy_completed";
    LogType["CHAMPION_TROPHY_CREATED"] = "champion_trophy";
    LogType["CHAMPION_TROPHY_IMPORTED"] = "champion_trophy_imported";
    LogType["CHAMPION_TROPHY_UPDATED"] = "champion_trophy_updated";
    LogType["CHAMPION_TROPHY_COMPLETED"] = "champion_trophy_completed";
    LogType["SPECIAL_TROPHY_CREATED"] = "special_trophy";
    LogType["SPECIAL_TROPHY_IMPORTED"] = "special_trophy_imported";
    LogType["SPECIAL_TROPHY_UPDATED"] = "special_trophy_updated";
    LogType["SPECIAL_TROPHY_COMPLETED"] = "special_trophy_completed";
    LogType["CUSTOM_ADMIN"] = "custom_admin_log";
})(LogType || (LogType = {}));
exports.LogType = LogType;
