import {IConnectArguments} from 'definitions/IConnectArguments';
import IFirebaseHttpsError from 'definitions/IFirebaseHttpsError';
import {httpsCallable} from 'firebase/functions';
import {ErrorCodes, IcoCross, IcoDone, ProgressDots} from 'myeuroloppet-shared';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {functions} from 'services/firebase';
import {T, useTranslate} from '@tolgee/react';

export default function AccountConnector(props: {isSkipped: boolean, email: string, onUserFound: (userId: string | null) => void, onSearching: (state: boolean) => void}) {
	const [searching, setSearching] = useState<boolean>(false);
	const [passportNumber, setPassportNumber] = useState<string>();
	const [dob, setDob] = useState<string>();
	const [userId, setUserId] = useState<string | null>(null);
	const [isUserFound, setIsUserFound] = useState<boolean>(false);
	const [isEmailMatch, setIsEmailMatch] = useState<boolean>(false);
	const [isDobMatch, setIsDobMatch] = useState<boolean>(false);
	const [searchError, setSearchError] = useState<string>();
	const inputPassportNumber = useRef<HTMLInputElement>(null);

	const t = useTranslate();

	const onPassword = async () => {
		//console.log('AccountConnector - Password submit')

		setSearchError(undefined);
		setIsUserFound(false);
		setUserId(null);

		setDob(undefined); // to be able to catch emailMatch X dobMatch in successful verification
		setIsDobMatch(false);
		setIsEmailMatch(false);

		if (!passportNumber && inputPassportNumber.current) {
			inputPassportNumber.current.value = '';
		}

		fetchUser();
	};

	const fetchUser = async () => {
		if (!passportNumber) return;
		//console.log('AccountConnector - fetch user')
		setSearching(true);
		props.onSearching(true);

		const args: IConnectArguments = {passportNumber: passportNumber, email: props.email};
		if (dob) {
			args.dob = dob;
		}
		try {
			const r = await httpsCallable(functions, 'findUserByPassport')(args);
			const userId = r.data as string;

			setSearchError(undefined);
			setIsUserFound(true);
			setUserId(userId);
			setIsDobMatch(true);
			if (!dob) setIsEmailMatch(true);
		}
		catch (e) {
			const error = e as IFirebaseHttpsError;

			if (error.details?.code === ErrorCodes.failedPrecondition) {
				setIsUserFound(true);
			}
			else if (error.details?.code === ErrorCodes.permissionDenied) {
				setIsUserFound(true);
				setSearchError('Verification failed.');
			}
			else {
				setIsUserFound(false);
				let message = e instanceof Error ? e.message : e as string;
				if (error.details?.code === ErrorCodes.notFound) {
					message = t('not found');
				}
				else if (error.details?.code === ErrorCodes.alreadyExists) {
					message = t('already used');
				}
				setSearchError(message);
			}
		}
		setSearching(false);
		props.onSearching(false);
	};

	useEffect(() => {
		console.log('AccountConnector - DOB change', dob, new Date(dob ?? ''))

		if (!dob || !passportNumber || passportNumber.length < 6) return;

		if (new Date(dob) < new Date('1901-01-01')) return;

		setSearchError(undefined);
		setUserId(null);
		setIsDobMatch(false);

		fetchUser();
	}, [dob])

	useEffect(() => {
		if (dob || !inputPassportNumber.current || searching) return;

		inputPassportNumber.current.focus();
	}, [searching]);

	useEffect(() => {
		//console.log('AccountConnector - userId change', userId)
		props.onUserFound(userId);
	}, [userId])

	useEffect(() => {
		//console.log('AccountConnector - skip change', props.isSkipped);
		if (props.isSkipped) {
			setUserId(null);
			setIsDobMatch(false);
			setDob(undefined);
			setIsEmailMatch(false);
			setIsUserFound(false);
			setPassportNumber(undefined);
			setSearchError(undefined);
			if (inputPassportNumber.current) {
				inputPassportNumber.current.value = '';
			}
		}
	}, [props.isSkipped])

	return (
		<div className='AccountConnector'>

			<div className={`AccountConnector__Content`}>
				<div className='AccountConnector__PassportNumber'>
					<div className='AccountConnector__PassportNumber__Input'>
							<input type="text" name='passportNumber' maxLength={20} pattern='/^\+?(0|[1-9]\d*)$/' placeholder='Passport Number'
					       ref={inputPassportNumber}
					       disabled={searching || props.isSkipped}
					       onChange={(e) => setPassportNumber(e.currentTarget.value)} />
						<button className='Button Button--success' onClick={onPassword}><T>Submit</T></button>
					</div>
					<div className='AccountConnector__PassportNumber__Info'>
						{isUserFound && <span className='AccountConnector__PassportNumber__Info__Response'><IcoDone /><T>Passport found</T></span>}
						{!isUserFound && searching && <div className='AccountConnector__PassportNumber__Info__SearchProgress'><T>Searching</T><ProgressDots /></div>}
						{!isUserFound && searchError && <div className='AccountConnector__PassportNumber__Info__SearchError'><IcoCross />{searchError}</div>}
					</div>
				</div>

				{isUserFound &&
					<div className='AccountConnector__SearchResult'>
						{isEmailMatch  && <div style={{display: 'none'}}><IcoDone /><T>email match</T></div>}
						{!isEmailMatch &&
							<>
								<div className='AccountConnector__SearchResult__Response' style={{display: 'none'}}><IcoCross/><T>Email doesn't match</T></div>
								<div className='AccountConnector__SearchResult__Label'><T>Please, provide the Date of Birth for verification.</T></div>
								<div>
									<input type="date" name='dob' onChange={(e) => setDob(e.currentTarget.value)} defaultValue={dob} disabled={searching} />
									<div className='AccountConnector__SearchResult__Info'>
										{searching && <div className='AccountConnector__SearchResult__Info__SearchProgress'><T>Verifying</T><ProgressDots /></div>}
										{searchError && <div className='AccountConnector__SearchResult__Info__SearchError'><IcoCross />{searchError}</div>}
										{isDobMatch && <span><IcoDone /><T>The Date of Birth matches.</T></span>}
									</div>
								</div>
							</>
						}
					</div>
				}
			</div>

		</div>
	)
}
