import FlashMessage from 'components/FlashMessagge';
import BarMessage from 'components/BarMessage';
import {useContext} from 'react';
import {AppContext} from 'services/AppContextProvider';


export default function MessageContainer() {
	const {state, dispatch} = useContext(AppContext);
	const messages = state.messages;
	const barMessages = state.barMessages;

	const onCloseFlashMessage = (id: string) => {
		console.log("onCloseFlashMessages", id)
		dispatch({type: 'flashRemove', payload: id});
	}

	const onCloseBarMessage = (id: string) => {
		console.log("onCloseBarMessage", id)
		dispatch({type: 'barMessageRemove', payload: id});
	}
	
	return (
		<>
			{messages.map((m, i) => <FlashMessage key={i} message={m} onClose={() => {onCloseFlashMessage(m.id)}}>{m.message}</FlashMessage>)}
			{barMessages.map((m, i) => <BarMessage key={i} message={m} onClose={() => {onCloseBarMessage(m.id)}}>{m.message}</BarMessage>)}
		</>
	)
}