import {useTranslate} from '@tolgee/react';
import Loader from 'components/Loader';
import {httpsCallable, HttpsCallableResult} from 'firebase/functions';
import {IcoDone} from 'myeuroloppet-shared';
import {ReactElement, useContext, useState} from 'react';
import {AppContext} from 'services/AppContextProvider';
import {logout, signInWithToken} from 'services/authentication';
import {functions} from 'services/firebase';

export default function LoginToken (): ReactElement {
	const appContext = useContext(AppContext);
	const [uid, setUid] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);

	const fetchToken = async () => {
		if (!uid) return;

		setLoading(true);

		try {
			const res = await httpsCallable<{ uid: string }>(functions, 'loginToken')({uid: uid}) as HttpsCallableResult<{ uid: string, token: string }>;

			await logout();

			signInWithToken(res.data.token);
		}
		catch (e) {
			setLoading(false);
			appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'User not found.'}});
		}
	}

	return (
		<div className='LoginToken'>
			<input name='uid' type='text' className='small' onChange={(e) => setUid(e.currentTarget.value)} title={useTranslate()('switch user')} placeholder='uid' />
			<button className='Button Button--small' onClick={fetchToken}><IcoDone /></button>
			{loading  && <Loader />}
		</div>
	)
}
