import {initializeFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {initializeApp} from 'firebase/app';
import {getFunctions} from 'firebase/functions';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
	apiKey: "AIzaSyDuFRJPDYXpkw_yjQ-LcBIrZUm-AVAkD-c",
	authDomain: "euroloppet-2dadf.firebaseapp.com",
	projectId: "euroloppet-2dadf",
	storageBucket: "euroloppet-2dadf.appspot.com",
	messagingSenderId: "619365902937",
	appId: "1:619365902937:web:2b280f8c6365ae41085f48",
	measurementId: "G-48H6PS79QS"
};

const firebaseConfigDev = {
	apiKey: "AIzaSyDuT07kJ4xuWwlyo2B0qmIKTmhTMDxqNWk",
	authDomain: "euroloppet-dev.firebaseapp.com",
	projectId: "euroloppet-dev",
	storageBucket: "euroloppet-dev.appspot.com",
	messagingSenderId: "827383525159",
	appId: "1:827383525159:web:dc0d542706900100be4302"
};

export const isProd = process.env.REACT_APP_ENV === 'prod';
export const isDev = process.env.REACT_APP_ENV === 'dev';
export const isLocal = process.env.NODE_ENV === "development"; //* based on npm start x build x test */

export const app = initializeApp(isProd ? firebaseConfig : firebaseConfigDev);

export const auth = getAuth(app);

export const db = initializeFirestore(app, {ignoreUndefinedProperties: true});

export const functions = getFunctions(app, 'europe-west3');

export const storage = getStorage(app);
