import {T} from "@tolgee/react";
import {useNavigate} from 'react-router-dom';

export default function NotFound() {
	const navigate = useNavigate();
	return (
		<div className='NotFound'>
			<p>
				<T>Page not found</T>
			</p>
			<p>
				<button className='ButtonLink' onClick={() => navigate(-1)}>&lt;&lt; <T>back</T></button>
			</p>
		</div>
	)
}
