"use strict";
exports.__esModule = true;
exports.CustomClaimsPermissionsMirror = void 0;
/** mirrors custom claims to database for querying */
var CustomClaimsPermissionsMirror;
(function (CustomClaimsPermissionsMirror) {
    CustomClaimsPermissionsMirror["isAdmin"] = "cmia";
    CustomClaimsPermissionsMirror["isTicketReceiver"] = "cmitr";
    CustomClaimsPermissionsMirror["isDeveloper"] = "cmid";
})(CustomClaimsPermissionsMirror = exports.CustomClaimsPermissionsMirror || (exports.CustomClaimsPermissionsMirror = {}));
