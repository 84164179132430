"use strict";
exports.__esModule = true;
exports.PassportCreatedBy = void 0;
var PassportCreatedBy;
(function (PassportCreatedBy) {
    PassportCreatedBy["USER"] = "user";
    PassportCreatedBy["ADMIN"] = "admin";
    PassportCreatedBy["CONNECT"] = "connect";
})(PassportCreatedBy || (PassportCreatedBy = {}));
exports.PassportCreatedBy = PassportCreatedBy;
