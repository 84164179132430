import {TolgeeProvider} from '@tolgee/react';
import {UI} from '@tolgee/ui';
import MessageContainer from 'components/MessageContainer';
import NotFound from 'components/NotFound';
import {connectFunctionsEmulator} from 'firebase/functions';
// import {connectFirestoreEmulator} from 'firebase/firestore';
import ContactPage from 'pages/ContactPage';
import DashboardPage from 'pages/DashboardPage';
import LoginPage from 'pages/LoginPage';
import OrderPage from 'pages/OrderPage';
import OrderDonePage from 'pages/OrderDonePage';
import RacerActionPage from 'pages/RacerActionPage';
import ResultsPage from 'pages/ResultsPage';
import SignInLandingPage from 'pages/SignInLandingPage';
import UserEditPage from 'pages/UserEditPage';
import WithNavigation from 'pages/WithNavigation';
import React from 'react';
import './scss/App.scss';
import logo from 'scss/assets/logo_vertikal.png';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {AppContextProvider} from 'services/AppContextProvider';
import {functions, isLocal, isProd} from 'services/firebase';
import {fetchDictionary} from 'services/tolgee';
import {UserContextProvider} from 'services/UserContextProvider';

function App() {
	if (isLocal) {
		connectFunctionsEmulator(functions, "localhost", 5001);
		// connectFirestoreEmulator(db, 'localhost', 8080);
	}

  return (
	  <AppContextProvider>
		  <TolgeeProvider
			  watch={true}
			  staticData={{
				  en: () => fetchDictionary('en.json'),
				  de: () => fetchDictionary('de.json'),
			  }}
			  apiUrl={isProd ? undefined : process.env.REACT_APP_TOLGEE_URL}
			  apiKey={isProd ? undefined : process.env.REACT_APP_TOLGEE_KEY}
			  ui={isProd ? undefined : UI}
		  >
			  <div className="App">
				  <div className="logo"><a href="/"><img src={logo} alt='logo' /></a></div>
				  <MessageContainer />
				  <UserContextProvider>
				    <Router>
					    <Routes>
					      <Route path='/login' element={<LoginPage />} />
						    <Route path='/order-done' element={<OrderDonePage />} />
						    <Route path='/signin' element={<SignInLandingPage />} />
						    <Route path='/' element={<WithNavigation />} >
							    <Route index element={<DashboardPage />} />
						      <Route path='/dashboard' element={<DashboardPage />} />
						      <Route path='/profile' element={<UserEditPage />} />
						      <Route path='/results' element={<ResultsPage />} />
						      <Route path='/racer-action' element={<RacerActionPage />} />
							    <Route path='/order' element={<OrderPage />} />
							    <Route path='/contact' element={<ContactPage />} />
					      </Route>
						    <Route path="*" element={<NotFound />} />
					    </Routes>
					  </Router>
				  </UserContextProvider>
			  </div>
		  </TolgeeProvider>
		</AppContextProvider>
  );
}

export default App;
