"use strict";
exports.__esModule = true;
exports.ResultType = void 0;
var ResultType;
(function (ResultType) {
    ResultType["IMPORT"] = "import";
    ResultType["LEGACY"] = "legacy";
    ResultType["MANUAL"] = "manual";
})(ResultType || (ResultType = {}));
exports.ResultType = ResultType;
