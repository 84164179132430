import {IcoHelp} from 'myeuroloppet-shared';
import React, {Dispatch, ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {T, useTranslate} from '@tolgee/react';

interface Props {
	title: string,
	dispatch: Dispatch<any>,
}

export default function BuyTile(props: Props): ReactElement {
	const t = useTranslate();

	const showInfo = () =>  {
		props.dispatch({type: 'flash', payload: {
			type: 'info',
				message: <div className="InfoMessage"><IcoHelp /><h3><T>Euroloppet supports all Euroloppet passport holders with various advantages and services, offers and actions.</T></h3>
				<ul>
					<li><T>As a Euroloppet passport holder you always start in the second starting block at all Euroloppet Races.</T></li>
					<li><T>You always pay the lowest registration fee.</T></li>
					<li><T>You benefit from cheaper training packages at the training center Ramsau am Dachstein in Austria.</T></li>
				</ul>
			</div>
		}});
	}

	const showHowTo = () =>  {
		props.dispatch({type: 'flash', payload: {
				type: 'info',
				message: <div className="InfoMessage"><IcoHelp /><h3><T>How to buy a new Euroloppet passport.</T></h3>
					<ul>
						<li><T>Click buy button</T></li>
						<li><T>If you are new user, insert a few personal data needed for the purchase and for later registration to races.</T></li>
						<li><T>You will be redirected to secured online payment gateway by Telecash.</T></li>
						<li><T>Select the payment method. Credit card or another online method.</T></li>
						<li><T>After successful purchease you will found the Passport here in your Euroloppet account, on your MyEuroloppet dashborad.</T></li>
					</ul>

					<p><T>We do not send the paper version to your home address anymore. You can download or print pdf you will find here in your account.</T></p>
				</div>
			}});
	}

	return (
		<div className="DashboardTile BuyTile">
			<div className='DashboardTile__Frame'>
				<h4>{props.title}</h4>
				<div className='DashboardTile__Frame__Image'>
					<img src='passport.png' alt='passport' />
				</div>
				<ul>
					<li>
						{t({
							key: 'You use your advantages',
							parameters: { strong: <strong /> },
							defaultValue: 'You use your advantages - <strong>for 3 years!</strong>',
						})}
					</li>
					<li><strong><T>Price</T>: 39 €</strong></li>
				</ul>
				<div className="lineWithIco clear"><span><T>What is the Euroloppet passport about?</T> </span> <span className="ico" title="More info" onClick={showInfo}><IcoHelp /></span></div>
				<div className="lineWithIco"><span><T>How to buy the passport</T> </span> <span className="ico" title="More info" onClick={showHowTo}><IcoHelp /></span>.</div>
				<div className="DashboardTile__Frame__ButtonCont">
					<Link to='/order' className='Button Button--success'><T>Buy</T></Link>
				</div>
			</div>
		</div>
	)
}
