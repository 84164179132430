import DiplomaPdfDownload from 'components/Trophies/DiplomaPdfDownload';
import TrophyProgress from 'components/Trophies/TrophyProgress';
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import React, {ReactElement, useContext} from 'react';
import {IRacerUser} from 'myeuroloppet-shared';
import {useNavigate} from 'react-router-dom';
import {AppContext} from 'services/AppContextProvider';

export default function TrophyRacer (props:{diplomaProps: ITrophyDiplomaProps, trophy: IRacerUser}): ReactElement|null {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const isActiveSeason = props.trophy.seasonId === (appContext.state.activeSeason ?? 0) - 1;
	const {diplomaProps, trophy} = props;

	const navigateToRacerAction = () => {
		if (isActiveSeason) {
			navigate('/racer-action');
		}
	}

	if (!trophy.completed && !trophy.races) return null; // there could be empty trophy because of deleted results

	if (!trophy.completed && trophy.seasonId < (appContext.state.activeSeason ?? 2222)) return null; // hide older incomplete trophies

	return (
		<div className={`Trophy Trophy--racer ${trophy.completed ? 'Trophy--completed' : ''} ${isActiveSeason ? 'Trophy--activeSeason' : ''}`} onClick={navigateToRacerAction}>
			<div className='Trophy__Cont'>
				<div className='Trophy__Image'><img src='cup1.png' alt='Racer Cup' /></div>
				<TrophyProgress count={3} completed={trophy.completed} doneRaces={trophy.races} />
			</div>
			<h4>{`Racer ${trophy.seasonId}`}</h4>
			{trophy.completed && <DiplomaPdfDownload {...diplomaProps} />}
		</div>
	)
}