import {pdf} from '@react-pdf/renderer';
import {PassportPdfDocument} from 'components/Passport/PassportPdfDocument';
import {useEffect, useState} from 'react';
import IUser from 'definitions/IUser';
import {T, useTranslate} from '@tolgee/react';

interface Props {
	user: IUser,
}

export default function PassportPdfDownload (props: Props) {
	const t = useTranslate();
	const [activated, setActivated] = useState(false);
	const [url, setUrl] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const generatePdf = async () => {
		try {
			const pdfBlob = await pdf(<PassportPdfDocument {...props} />).toBlob();
			setUrl(URL.createObjectURL(pdfBlob));
			setError(undefined);
		}
		catch (e) {
			console.log(e);
			setError(e instanceof Error ? e.message : e as string);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (!activated) return;

		setLoading(true);

		generatePdf();
	}, [activated])

	return (
		<div className='PassportPdfDownload'>
			{!activated &&
				<a className='Button Button--transparent' onClick={() => setActivated(true)}><T>Create pdf</T></a>
			}

			{activated &&
				<>
					<a className={`Button Button--transparent ${loading || error ? 'disabled' : ''}`}
					   href={url} target='_blank'
					   title={error ? t('Creating PDF file failed.') + error : t('Open pdf')}>
						<T>Open pdf</T>
					</a>

					{error && <span className='LoadingText error'><T>PDF file unavailable</T></span>}
					{loading && !error && <span className='LoadingText'><T>Creating PDF file ...</T></span>}

					<br />

					{!loading && !error &&
						<a className={`ButtonLink`}
						   href={url}
						   download={`Euroloppet-passport-${props.user.passport?.id}.pdf`}
						   title={error ? t('Creating PDF file failed.') + error : t('Download as pdf')}>
							<T>Download pdf</T>
						</a>
					}
				</>
			}
		</div>
	)
}
