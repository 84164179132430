import {IPropsChildren} from 'definitions/IPropsChildren';
import IUser from 'definitions/IUser';
import {IUserContextProps} from 'definitions/IUserContextProps';
import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {AppContext} from 'services/AppContextProvider';
import {auth} from 'services/firebase';
import {getUserById, isRacer} from 'services/User';

export const UserContextProvider = (props: IPropsChildren) => {
	// console.log('<<UserContextProvider>>')
	const [authUser, loadingAuth, errorAuth] = useAuthState(auth);
	const [userId, setUserId] = useState<string>();
	const [user, setUser] = useState<IUser | null>();
	const [loading, setLoading] = useState(loadingAuth);
	const [error, setError] = useState(errorAuth);
	const [refresh, setRefresh] = useState(0);
	const appContext = useContext(AppContext);

	// console.log('context-auth', authUser, loadingAuth, errorAuth)
	// console.log('context', user, loading, error)

	const reloadAuthUser = useCallback(() => {
		authUser?.reload();
	}, [authUser]);

	const getUserIdFromClaims = useCallback(async () => {
		// console.log("FETCH CLAIMS")
		try {
			const c = await authUser?.getIdTokenResult(true);
			// console.log(c?.claims);

			const userId = c?.claims.userId ? c.claims.userId as string : undefined;

			unstable_batchedUpdates(() => {
				setUserId(userId);
				setUser(null);
				/** null - important to differentiate google sign In vs. Up (google, email link) */
				setLoading(!!userId);
			});
		}
		catch (err) {
			setLoading(false);
			setError(new Error('Lost connection.'));
		}
	}, [authUser]);

	const fetchUser = useCallback(async () => {
		// console.log("FETCH USER", userId)

		if (!userId) return;

		try {
			const res = await getUserById(userId);

			unstable_batchedUpdates(() => {
				setUser(res);
				setLoading(false);
				setError(undefined);
			});
		} catch (err) {
			unstable_batchedUpdates(() => {
				setLoading(false);
				setError(err as Error);
			});
		}
	}, [userId]);

	const dispatchRefresh = useCallback((silent: boolean = false) => {
		if (silent) {
			fetchUser();
		}
		else {
			setRefresh(refresh => refresh + 1);
		}
	}, [refresh, authUser, fetchUser]);

	useEffect(() => {
		// console.log('store change', userId);

		/** logout state or error state */
		if (!authUser && user) {
			unstable_batchedUpdates(() => {
				setUser(undefined);
				setUserId(undefined);
			});
		}

		if (!authUser) return;

		if (loadingAuth) return;

		setLoading(true);

		if (!userId) {
			getUserIdFromClaims();
		}
		else {
			fetchUser();
		}
	}, [authUser, userId, refresh]);

	useEffect(() => {
		if (authUser && !loadingAuth) return; //* keep on when fetching user

		setLoading(loadingAuth);
	}, [loadingAuth]);

	useEffect(() => {
		if (!user || !appContext.state.activeSeason || user.isRacerLastSeason !== undefined) return;

		setUser({...user, isRacerLastSeason: isRacer(user, appContext.state.activeSeason - 1)});
	}, [user, appContext.state.activeSeason]);

	return (
		<UserContext.Provider value={{authUser, user, dispatchRefresh, reloadAuthUser, loading, error}}>
			{props.children}
		</UserContext.Provider>
	);
}

export const UserContext = createContext<IUserContextProps | undefined>(undefined);
