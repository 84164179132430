import AccountBox from 'components/User/AccountBox';
import UserForm from 'components/User/UserForm';
import {httpsCallable} from 'firebase/functions';
import {useUserContext} from 'hooks/useUserContext';
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {T, useTranslate} from '@tolgee/react';
import {IOrderForm, IcoDone, ProgressDots} from 'myeuroloppet-shared';
import IUser from 'definitions/IUser';
import {AppContext} from 'services/AppContextProvider';
import {functions, isProd} from 'services/firebase';
import {updateUser} from 'services/User';

enum OrderSteps {
	UserDetails = 1,
	Confirmation = 2,
	Order = 3,
	Payment = 4,
}

export default function OrderPage(): ReactElement {
	console.log('<<OrderPage>>');

	const appContext = useContext(AppContext);
	const {user} = useUserContext();
	const t = useTranslate();
	const hasPassport = Boolean(user?.passport);
	const fieldsToDisable = ['firstname', 'lastname', 'dob', 'gender', 'nationality'].filter((f) => hasPassport && user && user[f as keyof IUser]);
	const [step, setStep] = useState(hasPassport ? OrderSteps.Confirmation : OrderSteps.UserDetails);
	const [userDetails, setUserDetails] = useState<Partial<IUser>>(user ?? {});
	const [orderForm, setOrderForm] = useState<IOrderForm>();
	const actionUrl = isProd ? 'https://www.ipg-online.com/connect/gateway/processing' : 'https://test.ipg-online.com/connect/gateway/processing';
	
	const today = new Date();
	const validTo = user?.passport ? new Date(user?.passport?.validTo) : today;
	validTo.setUTCFullYear(validTo.getUTCFullYear() + 3);

	const finishStepUserDetails = (data: Partial<IUser>) => {
		setUserDetails(data);
		setStep(OrderSteps.Confirmation);
	}

	const finishStepConfirmation = async () => {
		if (!user) return;

		try {
			await updateUser(user.id, userDetails);

			setStep(OrderSteps.Order);

			await fetchOrder();
		}
		catch (e) {
			appContext.dispatch({type: 'flash', payload: {type: 'error', message: t('Request failed. Please, try again or contact support.')}});
			setStep(OrderSteps.UserDetails);
		}
	}

	const fetchOrder = async () => {
		const orderPassport = httpsCallable(functions, 'orderPassport');
		const orderArguments = {
			userId: user?.id,
			language: user?.lang || navigator.language,
		};

		const res = await orderPassport(orderArguments);
		const resData = res.data as IOrderForm;

		if (resData) {
			setOrderForm(resData);
			setStep(OrderSteps.Payment);
		}
	}

	useEffect(() => {
		console.log('step', step);
		if (step === OrderSteps.Payment) {
			const paymentForm: HTMLFormElement = document.getElementById('paymentForm') as HTMLFormElement;
			paymentForm.submit();
		}
	}, [step])


	return (
		<>
			{user &&
			<div className='OrderPage'>
				<section className="ProfileSection">
					<AccountBox user={user} displayName={false} displayNationality={false} />
				</section>

				<section className="OrderSection">
					<div className="box">
						<h1><T>Order new passport</T></h1>
						<p className='mb15'><T>Enter your user details in step 1. Confirm and proceed to pyment in step 2.</T></p>

						<div className={`Step Step--UserDetails ${step === OrderSteps.UserDetails ? 'Step--active' : ''}`}>
							<h3><T>Step 1 - User details</T> {step > OrderSteps.UserDetails && <><IcoDone /> <button className='ButtonLink' onClick={() => setStep(OrderSteps.UserDetails)}><T>Edit</T></button></>}</h3>
							<div className='Step__Content'>
								<UserForm user={user} onSubmit={finishStepUserDetails} submitLabel={t('Continue')} fieldsToDisable={fieldsToDisable} />
							</div>
							{step > OrderSteps.UserDetails && <div className='Step__Recap'>
								<div className='Step__Recap__Block'>
									<table>
										<tbody>
											<tr><th><T>Name</T>:</th><td>{userDetails.degree} {userDetails.firstname} {userDetails.lastname}</td></tr>
											<tr><th><T>Date of birth</T>:</th><td>{userDetails.dob ? (new Date(userDetails.dob)).toLocaleDateString() : '-'}</td></tr>
											<tr><th><T>Gender</T>:</th><td>{userDetails.gender}</td></tr>
											<tr><th><T>Nationality</T>:</th><td>{userDetails.nationality}</td></tr>
											<tr><th><T>Phone</T>:</th><td>{userDetails.phone}</td></tr>
										</tbody>
									</table>
								</div>
								<div className='Step__Recap__Block'>
									<table>
										<tbody>
											<tr>
												<th><T>Address</T>:</th>
												<td>
													<div>{userDetails.address?.street ?? '-'}</div>
													<div>{userDetails.address?.zip ?? '-'}, {userDetails.address?.city ?? '-'}</div>
													<div>{userDetails.address?.country ?? '-'}</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>}
						</div>

						<div className={`Step Step--Confirmation ${step === OrderSteps.Confirmation ? 'Step--active' : ''}`}>
							<h3><T>Step 2 - Confirmation and payment</T> {step > OrderSteps.Confirmation && <IcoDone />}</h3>
							<div className='Step__Content'>
								<div className='Step__Content__Block'>
									<div className='label'><T>Subject of order</T>:</div>
									<div className='subject mb15'><T>Euroloppet Passport with 3 years period</T></div>
									<div className='label'><T>Valid till</T>:</div>
									<div className='validity mb15'>{validTo.toLocaleDateString()}</div>
								</div>
								<div className='Step__Content__Block Step__Content__Block--price'>
									<div className='label'><T>Price</T>:</div>
									<div className='price'>39 €</div>
									<div className='submit'><button className='Button Button--success' onClick={finishStepConfirmation}><T>Confirm</T></button></div>
								</div>
							</div>
							{step > OrderSteps.Confirmation && <div className='Step__Recap'>
								<div className='Step__Recap__Block'>
									<table>
										<tbody>
										<tr><th><T>Order</T>:</th><td><T>Euroloppet Passport with 3 years period</T></td></tr>
										<tr><th><T>Valid till</T>:</th><td>{validTo.toLocaleDateString()}</td></tr>
										<tr className='price'><th><T>Price</T>:</th><td>39 €</td></tr>
										</tbody>
									</table>
								</div>
							</div>}
						</div>

						{step > OrderSteps.Confirmation && <div className={`Step Step--Payment  ${step > OrderSteps.Confirmation ? 'Step--active' : ''}`}>
							<div className='Step__Content'>
								{step === OrderSteps.Order && <p><T>Creating the order</T><ProgressDots /></p>}
								{step === OrderSteps.Payment && <p><T>Redirecting to the online payment</T><ProgressDots /></p>}

								{orderForm &&
									<form id='paymentForm' method="post" action={actionUrl}>
										{Object.entries(orderForm).map(([prop, val], i) => <input key={i} type='hidden' name={prop} defaultValue={val} readOnly={true}/>) }
									</form>
								}
							</div>
						</div>}

					</div>
				</section>
			</div>
			}
		</>
	);
}
