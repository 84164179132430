"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoCross() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 30 30', className: 'IcoCross' }, { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("path", { d: "m29.26927,24.39552l-9.49355,-9.49298l9.49183,-9.49298l-4.87964,-4.88279l-9.49355,9.49298l-9.49355,-9.49298l-4.87964,4.88279l9.49183,9.49298l-9.49355,9.49298l4.88309,4.88107l9.49183,-9.49298l9.49183,9.49298" }) }) })));
}
exports["default"] = IcoCross;
