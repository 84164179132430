"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoHelp() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 60 60", className: 'IcoHelp' }, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { id: "area", d: "m400,273c-14.91238,0 -27,12.08832 -27,27c0,14.91245 12.08761,26.99999 27,26.99999c14.91169,0 27,-12.08754 27,-26.99999c0,-14.91168 -12.08832,-27 -27,-27zm2.46689,42.55091l-5.02871,0l0,-4.82243l5.02871,0l0,4.82243zm0,-9.97158l0,1.59377l-5.02871,0l0,-1.96374c0,-5.92581 6.74361,-6.86617 6.74361,-11.07785c0,-1.92044 -1.71707,-3.39155 -3.96497,-3.39155c-2.32937,0 -4.37242,1.71634 -4.37242,1.71634l-2.86299,-3.55751c0,0 2.8219,-2.94304 7.6854,-2.94304c4.61984,0 8.90934,2.86085 8.90934,7.68399c0.00216,6.74718 -7.10926,7.52528 -7.10926,11.93958l0,0z" }), (0, jsx_runtime_1.jsx)("path", { id: "path", d: "m30,1c-16.01701,0 -29,13.00241 -29,29.04167c0,16.04009 12.98299,29.04166 29,29.04166c16.01626,0 29.00001,-13.00157 29.00001,-29.04166c0,-16.03926 -12.98375,-29.04167 -29.00001,-29.04167zm2.64962,45.7685l-5.40121,0l0,-5.18709l5.40121,0l0,5.18709zm0,-10.7256l0,1.71429l-5.40121,0l0,-2.11223c0,-6.3739 7.24314,-7.38538 7.24314,-11.91553c0,-2.06566 -1.84426,-3.64801 -4.25867,-3.64801c-2.50192,0 -4.6963,1.84612 -4.6963,1.84612l-3.07506,-3.82652c0,0 3.03093,-3.16558 8.25469,-3.16558c4.96205,0 9.5693,3.07718 9.5693,8.26503c0.00232,7.25738 -7.63588,8.09433 -7.63588,12.84242l0,0z" })] }) })));
}
exports["default"] = IcoHelp;
