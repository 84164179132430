import {IcoDone, IcoError} from 'myeuroloppet-shared';
import Overlay from 'components/Overlay';
import {IBarMessage} from 'definitions/IBarMessage';
import {HTMLAttributes, useState} from 'react';

interface OwnProps {
	message: IBarMessage,
	onClose: () => void
}

type Props = OwnProps & HTMLAttributes<HTMLElement>

export default function BarMessage (props: Props) {
	console.log("BarMessage")

	const [autoOff, setAutoOff] = useState(props.message.autoOff);

	return (
	  <Overlay className={`BarMessage BarMessage--${props.message.type}`} onClose={props.onClose} enableBgClick={true} autoOff={autoOff} y={props.message.y}>
		  <div className='BarMessage__Message' onClick={() => setAutoOff(100)}>
			  {props.message.type === 'success' && <IcoDone />}
			  {props.message.type === 'error' && <IcoError />}
			  {props.message.message}
			</div>
	  </Overlay>
	)
}