import {isSignInWithEmailLink, signInWithEmailLink, AuthErrorCodes} from '@firebase/auth';
import {T, useTranslate} from '@tolgee/react';
import {httpsCallable} from 'firebase/functions';
import {useUserContext} from 'hooks/useUserContext';
import {ProgressDots} from 'myeuroloppet-shared';
import React, {ReactElement, useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {logout} from 'services/authentication';
import {auth, functions} from 'services/firebase';
import {AppContext} from 'services/AppContextProvider';
import {getBrowserLocale} from 'services/Utils';

export default function SignInLandingPage(props: {}): ReactElement {
	console.log('<<SignInLandingPage>>');

	const t = useTranslate();
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const urlParams = new URLSearchParams(window.location.search);
	const connectUserId = urlParams.get('time');

	const {user, authUser, loading, dispatchRefresh} = useUserContext();
	const [email, setEmail] = useState('');
	const [displayLoader, setDisplayLoader] = useState(loading);

	const createUser = useCallback(
		async (args) => {
			try {
				const createUser = httpsCallable(functions, 'createUser');
				await createUser(args);
			} catch (e) {
			}
		}, []
	);

	const onEnterTrigger = async (e: React.KeyboardEvent<HTMLInputElement>, signFnc: () => void) => {
		if (e.key === 'Enter') {
			await signFnc();
		}
	}

	const signIn = async () => {
		if (!email) {
			appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'Email is required.'}});
			return;
		}

		setDisplayLoader(true);

		try {
			await signInWithEmailLink(auth, email, window.location.href);
			appContext.dispatch({type: 'flash', payload: {type: 'success', message: 'SignUp successful. Reset your password at future login.', autoOff: 5000}});
		}
		catch (e: any) {
			console.log(e);

			setDisplayLoader(false);

			if (e.code === AuthErrorCodes.INVALID_EMAIL) {
				appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'The email provided does not match the sign-in email address.', autoOff: 5000}});
			}
			else {
				appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'SignUp in failed. Try to reset your password.', autoOff: 5000}});
				navigate('/login');
			}
		}
	}

	const finishSignup = async () => {
		const args = {lang: getBrowserLocale(), userId: connectUserId};

		await createUser(args);

		dispatchRefresh();
	}

	useEffect(() => {
		if (!isSignInWithEmailLink(auth, window.location.href)) {
			navigate('/login');
			appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'This method is not allowed. Use another method to sign in.'}});
		}
	}, []);

	useEffect(() => {
		setDisplayLoader(loading);

		if (email) return;

		if (!loading && user) {
			logout(); //* logout, if another user is logged in
		}
	}, [loading]);

	useEffect(() => {
		if (loading || !email) return;

		if (authUser && user) {
			navigate('/dashboard');
		}
		if (authUser && user === null && connectUserId) {
			setDisplayLoader(true);
			finishSignup();
			return;
		}
		if (authUser && user === null) {
			navigate('/login'); /** if anonymous link & new account - offer to connect some legacy passport. But no scenario ends here for now. */
		}
	}, [authUser, user]);

	return (
		<div className="Page Page--simple SignInLandingPage">
			<div className="Page__Container">
				<h1><T>SignIn to MyEuroloppet</T></h1>
				<div className="box">
					{displayLoader && <div className='SignInLandingPage__Loading'>loading<ProgressDots /></div>}

					{!displayLoader &&
						<>
							<h3><T>Enter your email address for verification</T></h3>
							<input type="email" autoComplete="email" onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => onEnterTrigger(e, signIn)} placeholder={t('E-mail Address')}/>
							<button className="Button" onClick={signIn}><T>Sign in</T></button>
						</>
					}
				</div>
			</div>
		</div>
	);
}
