import {T, useTranslate} from '@tolgee/react';
import Loader from 'components/Loader';
import {httpsCallable} from 'firebase/functions';
import useDisplayFormErrors from 'hooks/useDisplayFormErrors';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {functions} from 'services/firebase';
import {AppContext} from 'services/AppContextProvider';

export default function ContactForm() {
	const {dispatch} = useContext(AppContext);
	const {register, handleSubmit, control, setValue, reset} = useForm({mode: 'all', shouldUnregister: true});
	const formState = useDisplayFormErrors(control);
	const t = useTranslate();
	const [sending, setSending] = useState(false);

	const onSubmit = handleSubmit(async (data) => {
		setSending(true);

		try {
			await httpsCallable(functions, 'sendTicket')(data);

			reset();
			dispatch({type: 'barMessage', payload: {type: 'success', message: <T>The ticket was send to office.</T>, autoOff: 5000}});
		}
		catch (e) {
			dispatch({type: 'flash', payload: {type: 'error', message: <h3><T>Send ticket failed.</T></h3>}});
		}

		setSending(false);
	});

	/** spam confusion */
	useEffect(() => {
		setValue('subject', 'Object object');
	}, [formState])

	return (
		<form className='ContactForm' onSubmit={onSubmit}>
			<div>
				<label><T>Subject</T></label>
				<input {...register('respect', {required: t('Enter the subject.'), maxLength: {value: 50, message: t('Maximal length is 50 chars.')}})} type='text' />
			</div>
			<div>
				<label><T>Text</T></label>
				<textarea {...register('undefined', {required: t('Enter the message.')})} rows={4} />
			</div>
			<div className='ContactForm__Message'>
				<input {...register('subject')} type='text' />
				<textarea {...register('message')} />
			</div>
			<div className='ContactForm__ButtonCont'><input className='Button' type='submit' value={t('send')} /></div>
			{sending && <Loader />}
		</form>
	);
}
