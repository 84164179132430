import {
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	GoogleAuthProvider,
	sendEmailVerification,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import {auth} from 'services/firebase';

const googleProvider = new GoogleAuthProvider();

export const signInWithToken = async (token: string) => {
	const res = await signInWithCustomToken(auth, token);
	return res.user.uid;
}

export const signInWithGoogle = async () => {
	googleProvider.setCustomParameters({prompt: 'select_account'});

	const res = await signInWithPopup(auth, googleProvider);
	return res.user.uid;
};

export const signInWithPassword = async (email: string, password: string) => {
	const res = await signInWithEmailAndPassword(auth, email, password);
	return res.user.uid;
};

export const registerWithPassword = async (email: string, password: string): Promise<string> => {
	const res = await createUserWithEmailAndPassword(auth, email, password);
	const user = res.user;

	await sendEmailVerification(user, {url: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/dashboard'});

	return user.uid;
};

export const checkEmailExists = async (email: string): Promise<boolean> => {
	const res = await fetchSignInMethodsForEmail(auth, email);
	return res.length > 0;
}

export const logout = () => {
	auth.signOut();
};
