import {TreeTranslationsData} from '@tolgee/core';

export async function fetchDictionary(file: string): Promise<TreeTranslationsData> {
	try {
		const response = await fetch(`https://firebasestorage.googleapis.com/v0/b/euroloppet-2dadf.appspot.com/o/web%2Fi18n%2F${file}?alt=media`);
		if (!response.ok) {
			throw new Error('Fetch failed.');
		}

		return response.json();
	}
	catch (e) {
		return import(`./../i18n/${file}`);
	}
}
