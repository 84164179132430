export default function IcoMenu() {
	return (
		<svg className='IcoMenu' viewBox='0 0 35 35' xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet">
			<g>
				<line y2="3" x2="35" y1="3" x1="0" />
				<line y2="12" x2="35" y1="12" x1="0" />
				<line y2="21" x2="35" y1="21" x1="0" />
				<line y2="30" x2="35" y1="30" x1="0" />
			</g>
		</svg>
	);
}
