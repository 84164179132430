import {T} from '@tolgee/react';
import Loader from 'components/Loader';
import VoucherPdfDownload from 'components/Trophies/VoucherPdfDownload';
import {getDocs, query, where} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {useUserContext} from 'hooks/useUserContext';
import {IRace, IRacerAction, IRacerUser} from 'myeuroloppet-shared';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import countryList from 'react-select-country-list';
import {AppContext} from 'services/AppContextProvider';
import {functions} from 'services/firebase';
import {racerAction, races} from 'services/firestoreGateway';

interface IRaceWithRacerAction extends IRace {
	isRacedLastSeason?: boolean,
	isDepleted?: boolean,
	isAllowed: boolean,
	isSelected?: boolean,
}

export default function RacerActionPage(): ReactElement {
	const appContext = useContext(AppContext);
	const {user, dispatchRefresh} = useUserContext();
	const [loading, setLoading] = useState<boolean>(false);
	const [isRacer, setIsRacer] = useState<boolean>();
	const [hasVoucher, setHasVoucher] = useState<boolean>();
	const [usersRaces, setUsersRaces] = useState<string[]>();
	const [racesWithRacerAction, setRacesWithRacerAction] = useState<IRaceWithRacerAction[]>();
	const [racerActionRace, setRacerActionRace] = useState<IRace>();
	const [trophy, setTrophy] = useState<IRacerUser>();
	const [racerActionItem, setRacerActionItem] = useState<IRacerAction>();
	const activeSeason = appContext.state.activeSeason;
	const isRacerAgain = user?.racerTrophies && user.racerTrophies.filter((r) => r.completed).length > 1;

	const sendRacerAction = async (raceId: string) => {
		setLoading(true);

		try {
			await httpsCallable(functions, 'racerAction')({raceId: raceId, trophyId: trophy?.id});

			dispatchRefresh();
		}
		catch (e) {
			appContext.dispatch({type: 'barMessage', payload: {type: 'error', message: 'Request failed.', autoOff: 2000}});
		}

		setLoading(false);
	}

	const fetchRacerAction = async () => {
		if (!trophy) return;

		/** query must be based on userId because of security rules */
		let ra: IRacerAction|undefined = undefined;
	  const raSnap = await getDocs(query(racerAction, where('userId', '==', user?.id)));
		raSnap.forEach((d) => {
			if (d.data().trophyId === trophy.id) {
				ra = d.data();
			}
		})

		setRacerActionItem(ra);
	}

	const fetchUsersRaces = async () => {
		if (!trophy || !activeSeason) return;

		const usersRacesActive: string[] = [];

		if (trophy.races.length > 0) {
			const racesSnap = await getDocs(query(races, where('id', 'in', trophy.races)));
			const series: (number | null)[] = [];
			racesSnap.forEach((r) => series.push(r.data().serieId));

			if (series.length > 0) {
				const usersSnap = await getDocs(query(races, where('seasonId', '==', activeSeason), where('serieId', 'in', series.filter(Number))));
				usersSnap.forEach((r) => usersRacesActive.push(r.id));
			}
		}

		setUsersRaces(usersRacesActive);
	}

	const fetchRaces = async () => {
		if (usersRaces === undefined || !activeSeason) return;

		const snap = await getDocs(query(races, where('seasonId', '==', activeSeason)));
		const racesAll: IRaceWithRacerAction[] = [];
		snap.forEach((r) => {
			const race = r.data() as IRaceWithRacerAction;

			if (!race.racerActionCount) return;

			race.isDepleted = (race.racerActionUsedCount ?? 0) >= race.racerActionCount;
			race.isRacedLastSeason = usersRaces?.includes(race.id);
			race.isAllowed = !race.isDepleted && !race.isRacedLastSeason && (!trophy?.freeRace || trophy.freeRace === race.id);
			race.isSelected = trophy?.freeRace === race.id;

			racesAll.push(race);
		});

		setRacesWithRacerAction(racesAll.sort((r1, r2) => r1.datefrom > r2.datefrom ? 1 : -1));
		setRacerActionRace(racesAll.find((r) => r.id === trophy?.freeRace));
	}

	useEffect(() => {
		fetchRaces();

		return () => setRacesWithRacerAction(undefined);
	}, [usersRaces, activeSeason]);

	useEffect(() => {
		if (!trophy) return;

		fetchUsersRaces();
		fetchRacerAction();

		return () => setUsersRaces(undefined);
	}, [trophy]);

	useEffect(() => {
		if (!user || !activeSeason) return;

		const t = user.racerTrophies?.find((r) => r.completed && r.seasonId === activeSeason - 1);
		if (t) {
			setTrophy(t);

			const voucher = user.racerTrophies?.find((ra) => ra.completed && ra.seasonId === (activeSeason - 1) && ra.freeRace);
			setHasVoucher(!!voucher);
		}
		else {
			setIsRacer(false);
		}

		return () => setTrophy(undefined);
	}, [user, activeSeason]);

	if (isRacer === false || (appContext.state.settings?.racerAction === false && hasVoucher === false)) {
		return <Navigate to="/dashboard" replace={true}/>;
	}

  return (
		<>
		  {user && activeSeason &&
			  <div className='RacerActionPage'>

				  <div className="box">
					  <div className='RacerActionPage__Logo'>Racer 3+1</div>

					  <div className='RacerActionPage__Content'>
						  <h2><T>Congratulations!</T> {isRacerAgain ? <T>You did it again!</T> : <T>You did it!</T>}</h2>
						  <div>
							  <T keyName='racer_action_subtitle' parameters={{b: <b /> }}>You have completed three races and won the title Euroloppet Racer 2022.</T>
						  </div>

						  {trophy && trophy.freeRace && racerActionItem && racerActionRace &&
								<div className='RacerActionPage__Content__Voucher'>
									<h3><T>EUROLOPPET Voucher</T></h3>
									<p><T>This voucher gives you free start at one Euroloppet race.</T></p>
									<div className='box RacerActionPage__Content__Voucher__Detail'>
										<div className='RacerActionPage__Content__Voucher__Detail__Code'>
											{racerActionItem.id}
										</div>
										<div className='RacerActionPage__Content__Voucher__Detail__Race'>
											<span>{racerActionRace.name}</span>
											<span className='RacerActionPage__Content__Voucher__Detail__Race__Date'>{racerActionRace.datefrom.toDate().toLocaleDateString()} - {racerActionRace.dateto.toDate().toLocaleDateString()}</span>
										</div>
										<div className='RacerActionPage__Content__Voucher__Detail__User'>
											<div>{racerActionItem.userDetails.firstname} {racerActionItem.userDetails.lastname}</div>
											{user.address?.country &&
												<div className='RacerActionPage__Content__Voucher__Detail__User__Address'>
													{user.address.street}<br />
													{user.address.zip}, {user.address.city}<br />
													{countryList().getLabel(user.address.country)}
												</div>
											}
											<div className='RacerActionPage__Content__Voucher__Detail__User__Dob'>
												<span className='RacerActionPage__Content__Voucher__Detail__User__Dob__Label'><T>DOB</T>:</span>
												{racerActionItem.userDetails.dob && <span>{new Date(racerActionItem.userDetails.dob).toLocaleDateString()}</span>}
											</div>
											<div className='RacerActionPage__Content__Voucher__Detail__User__Passport'>
												<span className='RacerActionPage__Content__Voucher__Detail__User__Passport__Label hidden-sm'><T>Passport No.</T></span>
												<span className='RacerActionPage__Content__Voucher__Detail__User__Passport__Label hidden-lg'><T>Pass</T></span>
												<span>{racerActionItem.userDetails.passportId}</span>
											</div>
										</div>
									</div>

									<VoucherPdfDownload racerAction={racerActionItem} user={user} race={racerActionRace} />
								</div>
						  }

						  {!trophy?.freeRace && racesWithRacerAction &&
							  <div className={`RacerActionPage__Content__Races`}>
								  <h3><T>Select the free race</T>:</h3>

								  <table>
									  {racesWithRacerAction.map((r, i) =>
										  <tbody key={i} className={`RacerActionPage__Content__Races__Race ${!r.isAllowed ? 'RacerActionPage__Content__Races__Race--notAllowed' : ''}`}>
											  <tr>
												  <td>{r.datefrom.toDate().toLocaleDateString()} - {r.dateto.toDate().toLocaleDateString()}</td>
												  <td className='RacerActionPage__Content__Races__Race__Title'>
													  <span className='bold'>{r.name}</span> <span className='italic'>({r.country})</span>
													</td>
												  <td className='RacerActionPage__Content__Races__Race__Action'>
													  {r.isAllowed && <button className='Button Button--success' onClick={() => sendRacerAction(r.id)}><T>Select</T></button>}
													  {r.isDepleted && <span className='error'><T>Free fees already depleted.</T></span>}
													  {r.isRacedLastSeason && <span className='error'><T>You have run this race last season.</T></span>}
													</td>
											  </tr>
										  </tbody>
									  )}
								  </table>
							  </div>
							}
					  </div>
					  {loading && <Loader />}
				  </div>
			  </div>
			}
		</>
  );
}
