import {T} from '@tolgee/react';
import {ISettingsPartnerItem} from 'myeuroloppet-shared';
import {ReactElement, useContext, useEffect, useState} from 'react';
import {AppContext} from 'services/AppContextProvider';

interface ILogoPartner extends ISettingsPartnerItem {
	bgd?: string
}

export default function Partners() {
	const appContext = useContext(AppContext);
	const [partnersPrimary, setPartnersPrimary] = useState<ILogoPartner[]>([]);
	const [partnersSeconadry, setPartnersSeconadry] = useState<ILogoPartner[]>([]);

	const getColorFromImage = (image: HTMLImageElement): string => {
		const w = image.width;
		const h = image.height;
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		if (!context) return 'transparent';
		// if (w === h) return 'transparent';

		context.drawImage(image, 0, 0, w, h);
		const imageData = context.getImageData(0, 0, w, h);

		if (imageData.data[4] === 0) {
			return 'transparent';
		}

		return `rgb(${imageData.data[0]},${imageData.data[1]},${imageData.data[2]})`;
	}

	const getLogoPartner = (p: ISettingsPartnerItem, i: number): ILogoPartner => {
		const partner: ILogoPartner = {...p};

		const img = new Image();
		img.crossOrigin = "anonymous";
		img.src = p.image;
		img.onload = () => {
			partner.bgd = getColorFromImage(img);

			/** redraw */
			if (p.type === 'primary') {
				setPartnersPrimary(partnersPrimary => [...partnersPrimary]);
			}
			else {
				setPartnersSeconadry(partnersSeconadry => [...partnersSeconadry]);
			}
		}

		return partner;
	}

	const getLogoPartnerHtml = (logo: ILogoPartner): ReactElement|null => {
		if (!logo.bgd) return null;

		const span = <span style={{backgroundColor: logo.bgd}}><img src={logo.image} alt={logo.name} crossOrigin='anonymous' /></span>;

		return logo.link ? <a href={logo.link} target='_blank'>{span}</a> : span;
	}

	useEffect(() => {
		if (!appContext.state.settings) return;

		/** to render in initial order, not the order images are loaded */
		setPartnersPrimary(appContext.state.settings.partners.primary.map((item, i) => getLogoPartner(item, i)));
		setPartnersSeconadry(appContext.state.settings.partners.secondary.map((item, i) => getLogoPartner(item, i)));

		return () => {
			setPartnersPrimary([]);
			setPartnersSeconadry([]);
		}
	}, [appContext.state.settings]);

	return (
		<div className='Partners box'>
			<h3><T>Partners</T></h3>
			<div className='Partners__Logos Partners__Logos--primary'>
				{partnersPrimary.map((logo, i) => <div key={i}>{getLogoPartnerHtml(logo)}</div>)}
			</div>
			<div className='Partners__Logos Partners__Logos--secondary'>
				{partnersSeconadry.map((logo, i) => <div key={i}>{getLogoPartnerHtml(logo)}</div>)}
			</div>
		</div>
	);
}
