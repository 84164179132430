import DiplomaPdfDownload from 'components/Trophies/DiplomaPdfDownload';
import TrophyProgress from 'components/Trophies/TrophyProgress';
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import {ISpecialTrophy, ISpecialTrophyUser} from 'myeuroloppet-shared';
import {ReactElement, useEffect, useState} from 'react';
import {getSpecialTrophy} from 'services/firestoreGateway';

export default function TrophySpecial (props:{diplomaProps: ITrophyDiplomaProps, trophy: ISpecialTrophyUser}): ReactElement {
	const [trophyDetail, setTrophyDetail] = useState<ISpecialTrophy | null>();

	useEffect(() => {
		const asyncFetch = async (id: string) => {
			return await getSpecialTrophy(id);
		}
		asyncFetch(props.trophy.trophyId).then((data) => setTrophyDetail(data));
	}, [props.trophy.trophyId])
	
	return (
		<div className={`Trophy Trophy--special ${props.trophy.completed ? 'Trophy--completed' : ''}`}>
			<div className='Trophy__Cont'>
				<div className='Trophy__Image'><img src='cup_x.png' alt='Special Cup' /></div>
				{trophyDetail && <TrophyProgress count={trophyDetail.raceCount} completed={false} doneRaces={props.trophy.races} />}
			</div>
			<h4>{`${trophyDetail?.name ?? 'Special ' + props.trophy.seasonId}`}</h4>
			{props.trophy.completed && trophyDetail && <DiplomaPdfDownload {...{...props.diplomaProps, diplomaImage: trophyDetail.diplom}} />}
		</div>
	)
}