import {DiplomaPdfDocument} from 'components/Trophies/DiplomaPdfDocument';
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import {ProgressDots} from 'myeuroloppet-shared';
import React, {useEffect, useState} from 'react';
import IcoDiploma from 'scss/assets/diplom_ico.png';
import {T, useTranslate} from '@tolgee/react';
import {pdf} from '@react-pdf/renderer';

export default function DiplomaPdfDownload (props: ITrophyDiplomaProps) {
	const t = useTranslate();
	const [activated, setActivated] = useState(false);
	const [url, setUrl] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const generatePdf = async () => {
		try {
			const pdfBlob = await pdf(<DiplomaPdfDocument {...props} />).toBlob();
			setUrl(URL.createObjectURL(pdfBlob));
			setError(undefined);
		}
		catch (e) {
			console.log(e);
			setError(e instanceof Error ? e.message : e as string);
		}
		setLoading(false);
	}

	const activateComponent = (e: React.MouseEvent) => {
		e.stopPropagation();
		setActivated(true);
	}

	useEffect(() => {
		if (!activated) return;

		setLoading(true);

		generatePdf();
	}, [activated])

	return (
		<div className='DiplomaPdfDownload'>
			{!activated &&
				<a className='ButtonLink' onClick={activateComponent}><img src={IcoDiploma} className='IcoDiploma' /><T>Create diploma</T></a>
			}

			{activated && url &&
				<a className={`ButtonLink ${loading || error ? 'disabled' : ''}`}
				   onClick={(e) => e.stopPropagation()}
				   href={url} target='_blank'
				   title={error ? t('Creating PDF file failed.') + error : t('Open pdf diploma')}>
					<img src={IcoDiploma} className='IcoDiploma' />
					<T>Open diploma</T>
				</a>
			}

			{activated && error && <span className='LoadingText error'><T>PDF file unavailable</T></span>}
			{activated && loading && !error && <span className='LoadingText'><img src={IcoDiploma} className='IcoDiploma' /><T>Creating PDF</T><ProgressDots count={3} /></span>}
		</div>
	)
}
