import AccountBox from 'components/User/AccountBox';
import UserForm from 'components/User/UserForm';
import {useUserContext} from 'hooks/useUserContext';
import React, {ReactElement, useContext, useEffect} from "react";
import {AppContext} from 'services/AppContextProvider';
import {updateUser} from 'services/User';
import IUser from 'definitions/IUser';
import {T, useTranslate} from '@tolgee/react';

export default function UserEditPage(): ReactElement {
	console.log('<<UserEditPage>>');

	const appContext = useContext(AppContext);
	const {user, dispatchRefresh} = useUserContext();
	const t = useTranslate();

	// console.log(authUser?.getIdTokenResult()) // ... customClaims read
	const saveUser = async (data: Partial<IUser>) => {
		if (!user) return;

		try {
			await updateUser(user.id, data, true);

			dispatchRefresh();
			return;
		} catch (err) {
			console.error(err);
		}
		appContext.dispatch({type: 'flash', payload: {type: 'error', message: <h3><T>User was not updated. Try again or contact support.</T></h3>}});
	}

	return (
		<>
			{user &&
			<div className='UserEditPage'>
				<section className="ProfileSection">
					<AccountBox user={user} displayName={false} displayNationality={false} isWithUpload={true} />
				</section>

				<section className="EditSection">
					<div className="box">
						<h3><T>Edit profile</T></h3>
						<UserForm user={user} onSubmit={saveUser} submitLabel={t('save')} fieldsToDisable={['firstname', 'lastname', 'dob', 'gender', 'nationality']} />
					</div>
				</section>
			</div>
			}
		</>
	);
}
