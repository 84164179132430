import {IBarMessage} from 'definitions/IBarMessage';
import {IFlashMessage} from 'definitions/IFlashMessage';
import {IPropsChildren} from 'definitions/IPropsChildren';
import {doc, getDoc} from 'firebase/firestore';
import {ISettingsMyEuroloppet, removeById, randomStringId} from 'myeuroloppet-shared';
import {createContext, Dispatch, useEffect, useReducer} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from 'services/firebase';
import {settings, getActiveSeasonId} from 'services/firestoreGateway';

interface AppState {
	messages: IFlashMessage[],
	barMessages: IBarMessage[],
	activeSeason?: number,
	settings?: ISettingsMyEuroloppet,
}

const initialState: AppState = {
	messages: [],
	barMessages: [],
}

interface Action<T> {
	type: string,
	[prop: string]: any
}

export const reducer = (state: AppState, action: Action<any>): AppState => {
	const newState = {...state};

	switch (action.type) {
		case 'flash':
			newState.messages = [...newState.messages];
			newState.messages.push({...action.payload, id: randomStringId(5)});
			return newState;

		case 'flashRemove':
			newState.messages = removeById(action.payload, newState.messages);
			return newState;

		case 'flashFlush':
			newState.messages = [];
			return newState;

		case 'barMessage':
			newState.barMessages = [...newState.barMessages];
			newState.barMessages.push({...action.payload, id: randomStringId(5)});
			return newState;

		case 'barMessageRemove':
			newState.barMessages = removeById(action.payload, newState.barMessages);
			return newState;

		case 'barMessageFlush':
			newState.barMessages = [];
			return newState;

		case 'activeSeason':
			newState.activeSeason = action.payload;
			return newState;

		case 'settings':
			newState.settings = action.payload;
			return newState;

		default:
			throw new Error('Reducer action mismatch.');
	}
}

export const AppContextProvider = (props: IPropsChildren) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [authUser] = useAuthState(auth);

	const fetchActiveSeason = async () => {
		const season = await getActiveSeasonId();
		dispatch({type: 'activeSeason', payload: season});
	}

	const fetchSettings = async () => {
		const settSnap = await getDoc(doc(settings, 'myEuroloppet'));
		if (!settSnap.exists()) return;

		const settingsDoc = settSnap.data().settings as ISettingsMyEuroloppet;
		dispatch({type: 'settings', payload: settingsDoc});
	}

	useEffect(() => {
		if (!authUser) return;

		fetchSettings();
		fetchActiveSeason();
	}, [authUser]);

	return (
		<AppContext.Provider value={{state, dispatch}}>
			{props.children}
		</AppContext.Provider>
	);
}

export const AppContext = createContext<{state: AppState, dispatch: Dispatch<Action<typeof reducer>>}>({
	state: initialState,
	dispatch: () => {}
});
