import {Snowflake} from 'myeuroloppet-shared';

interface Props {
	loading?: boolean
}

export default function Loader(props: Props) {
	return (
		<div className='Loader'>
			<Snowflake />
		</div>
	)
}
