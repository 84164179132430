import {T, useTranslate} from '@tolgee/react';
import {IcoCamera, Snowflake} from 'myeuroloppet-shared';
import ImageUpload from 'components/ImageUpload';
import {useUserContext} from 'hooks/useUserContext';
import React, {ReactElement, useContext, useState} from 'react';
import {storage} from 'services/firebase';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {AppContext} from 'services/AppContextProvider';
import {updateUser} from 'services/User';

interface Props {
	url?: string,
	withUpload?: boolean,
}

export default function Avatar(props: Props): ReactElement {
	const MAX_WIDTH = 800;
	const MAX_HEIGHT = 800;
	const {url, withUpload = false} = props;
	const [loading, setLoading] = useState(false);
	const {dispatch} = useContext(AppContext);
	const {user, dispatchRefresh} = useUserContext();
	const avatarUrl = url ?? user?.avatar ?? null;
	const t = useTranslate();

	const uploadAvatar = async (imageData: Blob) => {
		if (!user) return;
		setLoading(true);

		const storageRef = ref(storage, `/users/${user.id}/avatar.png`);
		await uploadBytes(storageRef, imageData);
		const url = await getDownloadURL(storageRef);
		try {
			await updateUser(user.id, {avatar: url});
			dispatch({type: 'barMessage', payload: {type: 'success', message: t('The Avatar successfully uploaded.'), autoOff: 4000, y: 1}});
		}
		catch (e) {
			dispatch({type: 'barMessage', payload: {type: 'error', message: t('Error during uploading the avatar.'), autoOff: 4000, y: 1}});
		}

		setLoading(false);

		dispatchRefresh();
	}

	return (
		<div className="Avatar">
			{avatarUrl && <img src={props.url ?? user?.avatar} referrerPolicy="no-referrer" alt='avatar' />}
			{!avatarUrl && <div className="Avatar__Missing"><T>profile picture</T></div>}
			{withUpload &&
				<>
					<ImageUpload onSubmit={uploadAvatar} maxWidth={MAX_WIDTH} maxHeight={MAX_HEIGHT} />
					<IcoCamera />
				</>
			}
			{loading && <Snowflake />}
		</div>
	);
}
