"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
function IcoRefresh() {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ className: 'IcoRefresh', viewBox: '0 0 20 20', xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "xMidYMid meet", version: "1.0" }, { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("path", { d: "m10.0995,2.75371c0.78575,0.00095 0.1212,-0.11478 0.8321,0.10303l-0.56262,0.93254l3.99369,0l-0.99868,-1.65752l-0.99791,-1.65703l-0.52531,0.87315c-0.98451,-0.34495 -0.63067,-0.29188 -1.74055,-0.29188c-5.10866,0 -9.24938,3.96851 -9.24938,8.86436c0,2.03194 0.72113,3.89885 1.92124,5.39462l1.40642,-1.03487c-0.97062,-1.20879 -1.55421,-2.71719 -1.55738,-4.35926c0.00707,-3.95823 3.34863,-7.16106 7.47838,-7.16713l0,0zm7.32911,1.77389l-1.40641,1.03536c0.97038,1.20833 1.55397,2.71578 1.55664,4.35834c-0.00707,3.9582 -3.34863,7.16057 -7.47862,7.1671c-0.73186,-0.00091 -0.44755,0.06964 -1.11503,-0.12109l0.52944,-0.87738l-3.99367,0l0.99794,1.65661l0.99866,1.65889l0.55677,-0.92597c0.94427,0.31503 0.9704,0.3062 2.02589,0.30665c5.10939,-0.00091 9.24941,-3.96942 9.25061,-8.86528c-0.0012,-2.03194 -0.72284,-3.89839 -1.92221,-5.39324l0,0z" }) }) })));
}
exports["default"] = IcoRefresh;
