"use strict";
exports.__esModule = true;
exports.normalizeNameToCompare = exports.normalizeStringId = exports.capitalize = exports.shortenText = exports.shortenId = exports.randomStringId = void 0;
function randomStringId(length) {
    var getString = function () { return Math.random().toString(36).substring(2); };
    var a = getString();
    while (a.length < length) {
        a = a + getString();
    }
    return a.substring(0, length);
}
exports.randomStringId = randomStringId;
function shortenId(id) {
    return shortenText(id, 5);
}
exports.shortenId = shortenId;
function shortenText(text, size) {
    if (text.length <= size) {
        return text;
    }
    return text.substring(0, size).padEnd(size + 3, '.');
}
exports.shortenText = shortenText;
function capitalize(text) {
    var arr = text.split(' ');
    for (var i in arr) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    return arr.join(' ');
}
exports.capitalize = capitalize;
function normalizeStringId(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]|[\.\\\/\*,]/g, '').trim();
}
exports.normalizeStringId = normalizeStringId;
function normalizeNameToCompare(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
exports.normalizeNameToCompare = normalizeNameToCompare;
