"use strict";
exports.__esModule = true;
exports.PassportStatus = void 0;
var PassportStatus;
(function (PassportStatus) {
    PassportStatus["ACTIVE"] = "active";
    PassportStatus["EXPIRED"] = "expired";
    PassportStatus["DEACTIVATED"] = "deactivated";
})(PassportStatus || (PassportStatus = {}));
exports.PassportStatus = PassportStatus;
