import useDisplayFormErrors from 'hooks/useDisplayFormErrors';
import IUser from 'definitions/IUser';
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import countryList from 'react-select-country-list';
import {T, useTranslate} from '@tolgee/react';
import CountryFlagImage from "./CountryFlagImage";

interface Props {
	user: IUser,
	onSubmit: (data: Partial<IUser>) => void,
	submitLabel: string,
	fieldsToDisable?: string[],
}

export default function UserForm(props: Props) {
	console.log('<<UserForm>>')

	const {register, handleSubmit, control} = useForm({mode: 'all'});
	const formState = useDisplayFormErrors(control);
	const t = useTranslate();
	const countries = [...[{label: '-' + t('select country') + '-', value: ''}], ...countryList().getData()];
	const {user} = props;
	const errors = formState.errors;
	const [nationality, setNationality] = useState<string|undefined>(user.nationality ? user.nationality.toLowerCase() : undefined);
	const [country, setCountry] = useState<string|undefined>(user.address?.country ? user.address?.country.toLowerCase() : undefined);

	console.log(errors, 'errors');

	const fieldsToDisable = props.fieldsToDisable?.filter((f, i) => Boolean(user[f as keyof IUser]));

	const onSubmit = handleSubmit((data) => {
		/* to be sure forbidden fields won't be updated */
		fieldsToDisable?.map(f => delete data[f as keyof IUser]);
		props.onSubmit(data);
	});

	useEffect(() => {
		fieldsToDisable?.map((f, i) => {
			const field = document.getElementsByName(f)[0];
			field.setAttribute('disabled', 'disabled');
		})
	}, [fieldsToDisable])

	useEffect(() => {
		const spaceElm = document.getElementsByClassName('UserForm__LastFlexspace')[0];
		const a = spaceElm.getElementsByTagName('a')[0];
		a.href = '/contact';
	}, [])

	return (
		<form className='UserForm' onSubmit={onSubmit}>
			<div className="row">
				<div>
					<label><T>Degree</T></label>
					<input defaultValue={user.degree} type="text" {...register("degree", {})} />
				</div>
				<div>
					<label><T>Firstname</T></label>
					<input defaultValue={user.firstname} type="text" {...register("firstname", {required: t('Enter your name.'), maxLength: {value: 50, message: t('Maximal length is 50 chars.')}})}  />
				</div>
				<div>
					<label><T>Lastname</T></label>
					<input defaultValue={user.lastname} type="text" {...register("lastname", {required: t('Enter your surname.'), maxLength: {value: 50, message: t('Maximal length is 50 chars.')}})}  />
				</div>
			</div>

			<div className="row">
				<div>
					<label><T>Date of birth</T></label>
					<input className={user.dob ? '' : 'empty'} defaultValue={user.dob?.substr(0, 10)} type="date" {...register("dob", {required: t('Enter the date of your birth.')})} autoComplete='bday' />
				</div>
				<div>
					<label><T>Gender</T></label>
					<select defaultValue={user.gender} {...register("gender", { required: t('Enter your gender.')})}>
						<option value="male">{t('male')}</option>
						<option value="female">{t('female')}</option>
					</select>
				</div>
				<div className="nationality">
					<label><T>Nationality</T></label>
					<CountryFlagImage country={nationality} alt='Nationality flag' title={nationality} />
					<select defaultValue={user.nationality} {...register("nationality", {required: t('Select your nationality.'), onChange: (e) => setNationality(e.currentTarget.value.toLowerCase())})} autoComplete='nationality'>
						{countries.map((c, i) => <option key={i} value={c.value}>{c.label}</option>)}
					</select>
				</div>
			</div>

			<div className="row">
				<div>
					<label><T>Club</T></label>
					<input defaultValue={user.club} type="text" {...register("club", {})} />
				</div>
				<div>
					<label><T>Phone</T></label>
					<input defaultValue={user.phone} type="tel" {...register("phone", {maxLength: {value: 13, message: t('Enter valid phone number')}})} autoComplete='tel' />
				</div>
				<div className='_flexspace'/>
			</div>

			<div className="row last">
				<fieldset>
					<legend><T>Address addition</T></legend>
					<div>
						<label><T>Street</T></label>
						<input defaultValue={user?.address?.street} type="text" {...register("address.street", {required: t('Enter your street.')})} autoComplete="section-adr street-address" />
					</div>
					<div>
						<label><T>City</T></label>
						<input defaultValue={user?.address?.city} type="text" {...register("address.city", {required: t('Enter your city.')})} autoComplete="section-adr address-level2" />
					</div>
					<div>
						<label><T>Postal code</T></label>
						<input defaultValue={user?.address?.zip} type="text" {...register("address.zip", {required: t('Enter your zip code.')})} autoComplete="section-adr postal-code" />
					</div>
					<div className="country">
						<label><T>Country</T></label>
						<CountryFlagImage country={country} alt='Country flag' title={country} />
						<select defaultValue={user?.address?.country} {...register("address.country", {required: t('Select your country.'), onChange: (e) => setCountry(e.currentTarget.value.toLowerCase())})} autoComplete="section-adr country">
							{countries.map((c, i) => <option key={i} value={c.value}>{c.label}</option>)}
						</select>
					</div>
				</fieldset>
				<div className='_flexspace UserForm__LastFlexspace'>
					{t({
						key: 'If you want to change locked properties, please, contact support.',
						parameters: { a: <a /> },
						defaultValue: 'If you want to change locked properties, please, <a>contact support</a>.',
					})}
				</div>
				<div className="submit">
					<input type="submit" className="Button Button--success" value={props.submitLabel} />
				</div>
			</div>
		</form>
	);
}