"use strict";
exports.__esModule = true;
exports.Countries = void 0;
/**
 * translator for legacy country table
 * key = "c" + legacyId
 */
exports.Countries = {
    c1: { code: 'CZ', legacyCode: 'CZE', title: 'Česká republika' },
    c2: { code: 'SK', legacyCode: 'SVK', title: 'Slovenská republika' },
    c3: { code: 'DE', legacyCode: 'GER', title: 'Německo' },
    c4: { code: 'AT', legacyCode: 'AUT', title: 'Rakousko' },
    c5: { code: 'IT', legacyCode: 'ITA', title: 'Itálie' },
    c6: { code: 'PL', legacyCode: 'POL', title: 'Polsko' },
    c7: { code: 'CH', legacyCode: 'SUI', title: 'Švýcarsko' },
    c8: { code: 'FR', legacyCode: 'FRA', title: 'Francie' },
    c9: { code: 'NL', legacyCode: 'NED', title: 'Nizozemsko' },
    c10: { code: 'BE', legacyCode: 'BEL', title: 'Belgie' },
    c11: { code: 'RU', legacyCode: 'RUS', title: 'Rusko' },
    c12: { code: 'BY', legacyCode: 'BLR', title: 'Bělorusko' },
    c13: { code: 'HU', legacyCode: 'HUN', title: 'Maďarsko' },
    c14: { code: 'SI', legacyCode: 'SLO', title: 'Slovinsko' },
    c15: { code: 'FI', legacyCode: 'FIN', title: 'Finsko' },
    c16: { code: 'NO', legacyCode: 'NOR', title: 'Norsko' },
    c17: { code: 'SE', legacyCode: 'SWE', title: 'Švédsko' },
    c18: { code: 'AU', legacyCode: 'AUS', title: 'Austrálie' },
    c19: { code: 'EE', legacyCode: 'EST', title: 'Estonsko' },
    c20: { code: 'LT', legacyCode: 'LAT', title: 'Lotyšsko' },
    c21: { code: 'LV', legacyCode: 'LIT', title: 'Litva' },
    c22: { code: 'DK', legacyCode: 'DEN', title: 'Dánsko' },
    c23: { code: 'LI', legacyCode: 'LIE', title: 'Lichtenštejnsko' },
    c24: { code: 'IE', legacyCode: 'IRL', title: 'Irsko' },
    c25: { code: 'US', legacyCode: 'USA', title: 'Spojené státy americké' },
    c26: { code: 'GB', legacyCode: 'GBR', title: 'Velká Británie' },
    c27: { code: 'CA', legacyCode: 'CAN', title: 'Kanada' },
    c28: { code: 'ES', legacyCode: 'ESP', title: 'Španělsko' },
    c29: { code: 'IL', legacyCode: 'ISR', title: 'Izrael' },
    c30: { code: 'IS', legacyCode: 'ICE', title: 'Island' },
    c31: { code: 'JP', legacyCode: 'JPN', title: 'Japonsko' },
    c32: { code: 'UA', legacyCode: 'UKR', title: 'Ukrajina' },
    c33: { code: 'LX', legacyCode: 'LUX', title: 'Lucembursko' },
    c34: { code: 'CL', legacyCode: 'CHL', title: 'Chile' },
    c35: { code: 'NZ', legacyCode: 'NZL', title: 'Nový Zéland' },
    c74: { code: 'BG', legacyCode: 'BUL', title: 'Bulharsko' }
};
