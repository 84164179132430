"use strict";
exports.__esModule = true;
var HttpApi;
(function (HttpApi) {
    var Scope;
    (function (Scope) {
        Scope["passportCheck"] = "passport_check";
        Scope["listRacers"] = "list_racers";
        Scope["listChampions"] = "list_champions";
    })(Scope = HttpApi.Scope || (HttpApi.Scope = {}));
})(HttpApi || (HttpApi = {}));
exports["default"] = HttpApi;
