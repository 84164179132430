import {T} from '@tolgee/react';
import TrophyChampion from 'components/Trophies/TrophyChampion';
import TrophyRacer from 'components/Trophies/TrophyRacer';
import TrophySpecial from 'components/Trophies/TrophySpecial';
import ITrophyDiplomaProps from 'definitions/ITrophyDiplomaProps';
import {useUserContext} from 'hooks/useUserContext';
import {ReactElement} from 'react';
import {IChampionUser, IRacerUser, ISpecialTrophyUser, sortBySeason} from 'myeuroloppet-shared';

export default function Trophies(): ReactElement | null {
	const {user} = useUserContext();

	const trophies: (IRacerUser | IChampionUser | ISpecialTrophyUser)[] = [...(user?.racerTrophies || []), ...(user?.championTrophies || []), ...(user?.specialTrophies || [])];
	trophies.sort(sortBySeason).reverse();

	if (!user) return null;

	const userProps: Partial<ITrophyDiplomaProps> = {firstname: user.firstname ?? '', lastname: user.lastname ?? '', nationality: user.nationality ?? ''};
	
	return (
			<div className='Trophies'>
			<div className='box'>
				<h3><T>Trophies</T></h3>
				<div className='Trophies__Scroll'>
					{trophies.map((a, i) => {
						if ('trophyId' in a) return <TrophySpecial diplomaProps={{...userProps, season: a.seasonId} as ITrophyDiplomaProps} trophy={a} key={i} />
						if ('series' in a) return <TrophyChampion diplomaProps={{...userProps, season: a.seasonTo, diplomaImage: 'champion.jpg'} as ITrophyDiplomaProps} trophy={a} key={i} />
						return <TrophyRacer diplomaProps={{...userProps, season: a.seasonId, diplomaImage: 'racer.jpg'} as ITrophyDiplomaProps} trophy={a} key={i} />
					})}
					{trophies.length === 0 && <div><T>None yet</T></div>}
				</div>
			</div>
		</div>
	)
}
