import React, {ReactElement} from 'react';

interface Props {
}

export default function ResultsPage(props: Props): ReactElement {
	
  return (
		
			  <section className="Results">
				  <div className="box">
					 results...
				  </div>
			  </section>
  );
}
