import {Document, Page, Text, View, Link, Image, StyleSheet, Font} from "@react-pdf/renderer";
import {IRacerAction, IRace} from 'myeuroloppet-shared';
import IUser from 'definitions/IUser';
import React, {ReactElement} from 'react';
import countryList from 'react-select-country-list';
import fontReg from 'scss/font/Josefin_Sans/JosefinSans-Regular.ttf';
import fontBold from 'scss/font/Josefin_Sans/JosefinSans-Bold.ttf';
import image from 'scss/assets/racer_voucher_800x400.jpg';

Font.register({ family: 'JosefinSans', fonts: [
	{ src: fontReg },
	{ src: fontBold, fontWeight: 500 },
]});

const styles = StyleSheet.create({
	pageStyle: {
		backgroundColor: 'white',
		padding: 0,
		fontFamily: 'JosefinSans',
		fontSize: 12,
		position: 'relative',
		lineHeight: 1.3,
	},
	race: {
		position: 'absolute',
		top: 54,
		left: 25,
		fontSize: 25,
		fontWeight: 'bold',
	},
	date: {
		position: 'absolute',
		right: 25,
		top: 57,
		fontSize: 20,
		fontWeight: 'bold',
	},
	code: {
		position: 'absolute',
		bottom: 62,
		right: 86,
		fontSize: 30,
		fontWeight: 'bold'
	},
	dob_passport: {
		fontSize: 15,
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
});

export function VoucherPdfDocument(props: {racerAction: IRacerAction, user: IUser, race: IRace}): ReactElement {
	const {racerAction, user, race} = props;

	return (
		<Document>
			<Page size={[300, 600]} orientation='landscape' style={styles.pageStyle}>
				<Image src={image} style={{width: '100%', position: 'absolute', left: 0, top: 0, zIndex: 3}} />

				<View fixed style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 299, zIndex: 2}}>
					<View style={styles.code}><Text>{racerAction.id}</Text></View>
					<View style={styles.race}><Text>{race.name}</Text></View>
					<View style={styles.date}><Text>{race.datefrom.toDate().toLocaleDateString()} - {race.dateto.toDate().toLocaleDateString()}</Text></View>
					<View style={{position: 'absolute', top: 109, left: 50, fontSize: 20,	fontWeight: 'bold'}}>
						<Text>{racerAction.userDetails.firstname} {racerAction.userDetails.lastname}</Text>
						{user.address?.country &&
							<View style={{fontSize: 16}}>
								<Text>{user.address.street}</Text>
								<Text>{user.address.zip}, {user.address.city}</Text>
								<Text>{countryList().getLabel(user.address.country)}</Text>
							</View>
						}
						<View style={{...styles.dob_passport, marginTop: 30}}>
							<Text style={{fontWeight: 'normal'}}>DOB: </Text>
							{racerAction.userDetails.dob && <Text>{new Date(racerAction.userDetails.dob).toLocaleDateString()}</Text>}
						</View>
						<View style={styles.dob_passport}>
							<Text style={{fontWeight: 'normal'}}>Passport No. </Text>
							<Text>{racerAction.userDetails.passportId}</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
}
