import {useUserContext} from 'hooks/useUserContext';
import {ReactElement, useContext, useEffect, useState} from 'react';
import {T} from '@tolgee/react';
import {IUserResult, ResultFlags, Snowflake} from 'myeuroloppet-shared';
import {AppContext} from 'services/AppContextProvider';
import {collection, doc, getDocs, orderBy, query} from 'firebase/firestore';
import {users} from '../services/firestoreGateway';

export default function UserResults(): ReactElement {
	const {user} = useUserContext();
	const appContext = useContext(AppContext);
	const [results, setResults] = useState<IUserResult[]>();

	const fetchResults = async () => {
		if (!user) return;

		try {
			const snapshot = await getDocs(query(collection(doc(users, user.id), 'results'), orderBy('raceDate', 'desc')));

			const buffer: IUserResult[] = [];
			snapshot.forEach((doc) => {
				const result = doc.data() as IUserResult;
				if (result.flags && result.flags & ResultFlags.IMPORT_PASSPORT_INVALID && ~result.flags & ResultFlags.IMPORT_INVALID_ACCEPTED) {
					return;
				}
				buffer.push(result);
			})

			setResults(buffer);
		}
		catch (e) {
			appContext.dispatch({type: 'flash', payload: {type: 'error', message: 'Load results failed.'}})
		}
	}

	useEffect(() => {
		fetchResults();
	}, [])

	return (
		<div className='UserResults'>
			<div className='box'>
				<h3><T>Results</T></h3>
				<div>
					<table>
						<thead>
							<tr>
								<th><T>season.</T></th>
								<th><T>race.</T></th>
								<th><T>time.</T></th>
								<th><T>event.</T></th>
								<th><T>gender position.</T></th>
								<th><T>category.</T></th>
								<th><T>category position.</T></th>
							</tr>
						</thead>
						<tbody>
						{!results &&
							<tr>
								<td colSpan={7} style={{backgroundColor: 'white', textAlign: 'center'}}><Snowflake /></td>
							</tr>
						}
						{results && results.map((r, i) =>
							<tr key={i}>
								<td className='season'>{r.seasonId}</td>
								<td className='raceName'>{r.raceName}</td>
								<td className='time'><span className='label'><T>time.</T></span>{r.time}</td>
								<td className='event'><span className='label'><T>event.</T></span>{r.event}</td>
								<td className='genderPos'><span className='label'><T>gender position.</T></span>{r.posGender}</td>
								<td className='category'><span className='label'><T>category.</T></span>{r.category}</td>
								<td className='catPos'><span className='label'><T>category position.</T></span>{r.posCat}</td>
							</tr>
						)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}