"use strict";
exports.__esModule = true;
exports.ResultsImportRecord = void 0;
var ResultsImportRecord = /** @class */ (function () {
    function ResultsImportRecord(event, category, posGender, posCat, status, time, passport, lastname, firstname, yob, gender, nationality, club, street, city, zip, country, email, lang) {
        this.event = event;
        this.category = category;
        this.posGender = posGender;
        this.posCat = posCat;
        this.status = status;
        this.time = time;
        this.passport = passport;
        this.lastname = lastname;
        this.firstname = firstname;
        this.yob = yob;
        this.gender = gender;
        this.nationality = nationality;
        this.club = club;
        this.street = street;
        this.city = city;
        this.zip = zip;
        this.country = country;
        this.email = email;
        this.lang = lang;
    }
    return ResultsImportRecord;
}());
exports.ResultsImportRecord = ResultsImportRecord;
