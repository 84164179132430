"use strict";
exports.__esModule = true;
exports.ErrorCodes = void 0;
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["ok"] = "ok";
    ErrorCodes["cancelled"] = "cancelled";
    ErrorCodes["unknown"] = "unknown";
    ErrorCodes["invalidArgument"] = "invalid-argument";
    ErrorCodes["deadlineExceeded"] = "deadline-exceeded";
    ErrorCodes["notFound"] = "not-found";
    ErrorCodes["alreadyExists"] = "already-exists";
    ErrorCodes["permissionDenied"] = "permission-denied";
    ErrorCodes["resourceExhausted"] = "resource-exhausted";
    ErrorCodes["failedPrecondition"] = "failed-precondition";
    ErrorCodes["aborted"] = "aborted";
    ErrorCodes["outOfRange"] = "out-of-range";
    ErrorCodes["unimplemented"] = "unimplemented";
    ErrorCodes["internal"] = "internal";
    ErrorCodes["unavailable"] = "unavailable";
    ErrorCodes["dataLoss"] = "data-loss";
    ErrorCodes["unauthenticated"] = "unauthenticated";
    ErrorCodes["auth_wrongPassword"] = "auth/wrong-password";
    ErrorCodes["auth_notFound"] = "auth/user-not-found";
    ErrorCodes["auth_invalidEmail"] = "auth/invalid-email";
})(ErrorCodes || (ErrorCodes = {}));
exports.ErrorCodes = ErrorCodes;
