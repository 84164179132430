import Overlay from 'components/Overlay';
import {IFlashMessage} from 'definitions/IFlashMessage';
import {HTMLAttributes} from 'react';

interface OwnProps {
	message: IFlashMessage,
	onClose: () => void
}

type Props = OwnProps & HTMLAttributes<HTMLElement>

export default function FlashMessage (props: Props) {
	console.log("FlashMessage")

	return (
	  <Overlay className={`FlashMessage FlashMessage--${props.message.type}`} onClose={props.onClose} autoOff={props.message.autoOff} enableBgClick={true}>
		  <div className='FlashMessage__Message'>{props.message.message}</div>
	  </Overlay>
	)
}